import React, { useEffect, useState } from 'react'
import TablePro from '../../../components/newTable/TablePro'
import { getUtilityPiiFailedReason, getUtilityPiiHistoryById } from '../../../hooks/apis/useAppSafeApi'
import { BiCross } from 'react-icons/bi'
import { useUserContext } from '../../../hooks/UserContext'
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { useNavigate } from 'react-router-dom'

const DetailTablesData = ({ data, onCancel }) => {
    const navigate = useNavigate();
    const [whenFailed, setWhenFailed] = useState([])
    const [detailsData, setDetailsData] = useState([])
    const { user, setUser, setLoading } = useUserContext();
    const [error, setError] = useState(null);
    useEffect(() => {
        setLoading(true);
        setError(null);

        getUtilityPiiHistoryById(data.id)
            .then(res => {
                console.log("details data:: ", res);
                setDetailsData(res);
            })
            .catch(err => {
                console.error("Error fetching data: ", err);
                setError('Error fetching data');
            })
            .finally(() => {
                setLoading(false);
            });
    }, [data]);
    const getFailedData = (item) => {
        if (item.status.toLowerCase() === 'failed') {
            getUtilityPiiFailedReason(item.runId).then(res => {
                console.log("details data:: ", res);
                setWhenFailed(res);
            })
        }

    }
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: '1050',
    };
    const columns = [

        {
            name: 'Table Name',
            field: 'tableName',
            type: "customControl",
            fnRenderCustomControl: (item) => {
                return <spam className={`"font-5" ${item.status.toLowerCase() === 'failed' && "cursor-pointer"}`} onClick={() => getFailedData(item)}>{item.tableName}</spam>
            }
        },
        {
            name: 'Status',
            field: 'status',
        }
    ]
    return (<>
        {whenFailed?.length > 0 && <><div className="panel-utility">
            <div className="panel-screen h-100"></div></div></>}

        <div style={modalStyle} className="modal-dialog modal-dialog-centered " role="document">
            <div  >

                <div className="modal-content" style={{ border: '1px solid var(--gray-300, #D0D5DD)' }}>
                    <div className="modal-header">
                        <h5 className="modal-title font-5 cursor-pointer" id="exampleModalLongTitle" onClick={() => navigate("/utilitySetHomePage")}> <BsFillArrowRightCircleFill /> {data?.name} </h5>
                        <button onClick={() => onCancel({ id: '', name: '' })} type="button" className="close btn btn-transparent border-0 font-6" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" className='font-6' >&times;</span>
                        </button>
                    </div>
                    <div className="modal-body font-4">
                        {detailsData.length > 0 ? <TablePro columns={columns} data={detailsData.sort(
                            (a, b) => new Date(b.startTime) - new Date(a.startTime)
                        )} minRows={4} /> : error ? error : 'Loading...'}
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default DetailTablesData