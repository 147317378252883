import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { chat } from "../../hooks/apis/aiSafe";
import { useTranslation } from "react-i18next";
import "./MainAiSafe.scss";
import ConversationEntry from "./main/ConversationEntry";
import PrivacySettings from "./main/PrivacySettings";
import { LuSendHorizonal } from "react-icons/lu";

import img1 from "../../images/setting.svg";
import img2 from "../../images/aiSafe.svg";
import { GoPaperclip } from "react-icons/go";
import { serverAxios } from "../../utils/AxiosUtils";

const MainAiSafe = () => {
  const { t } = useTranslation("aisafe");
  const navigate = useNavigate();
  const { id } = useParams(); // Get the id from the URL

  const [privacySettings, setPrivacySettings] = useState({
    Names: true,
    Emails: true,
    Phone: false,
  });
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [error, setError] = useState("");
  const [hasLanded, setHasLanded] = useState(true);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload percentage
  const [uploadedFileName, setUploadedFileName] = useState(""); // Track uploaded file name
  const [isSendDisabled, setIsSendDisabled] = useState(false); // Disable send button during upload4
  const [idchat, setIdChat] = useState(null);

  const conversationHistoryRef = useRef(null);
  const textareaRef = useRef(null);
  const sidebarRef = useRef(null);

  const handleCheckboxChange = (key) => {
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };

  const handleChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    const tempEntry = {
      originalPrompt: trimmedPrompt,
      anonymizedPrompt: "Loading...",
      chatResponse: "Loading...",
    };

    setConversationHistory((prevHistory) => [...prevHistory, tempEntry]);
    setLoading(true);
    setError("");
    setHasLanded(false);
    setPrompt("");

    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );

      const new_chat = id ? id : "True";
      const new_chat2 = idchat ? idchat : "False";

      const chatResponse = await chat(
        trimmedPrompt,
        choices,
        new_chat,
        new_chat2
      );
      const { data, message, response_code, success } = chatResponse;

      if (response_code !== 200 || !success) {
        throw new Error(message);
      }

      const newEntry = {
        originalPrompt: prompt, // User's message
        anonymizedPrompt: data[1], // Anonymized message
        chatResponse: data[3], // Assistant's response
      };

      setConversationHistory((prevHistory) =>
        prevHistory.slice(0, -1).concat(newEntry)
      );

      // Update the URL with the id from data[0]
      const newId = data[0];
      navigate(`/ai-safe/${newId}`);

      setLoading(false);
    } catch (error) {
      setError(
        "An error occurred: " +
          (error.response?.status === 500
            ? "Internal Server Error"
            : error.message)
      );
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleChat();
    } else if (e.key === "Enter" && e.shiftKey) {
      setPrompt((prev) => prev + "\n");
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = conversationHistoryRef.current.scrollHeight;
      const scrollTop = conversationHistoryRef.current.scrollTop;
      const clientHeight = conversationHistoryRef.current.clientHeight;

      if (scrollHeight - scrollTop > clientHeight + 100) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (conversationHistoryRef.current) {
        conversationHistoryRef.current.removeEventListener(
          "scroll",
          handleScroll
        );
      }
    };
  }, []);

  useEffect(() => {
    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.scrollTop =
        conversationHistoryRef.current.scrollHeight;
    }
  }, [conversationHistory]);

  const scrollToBottom = () => {
    if (conversationHistoryRef.current) {
      conversationHistoryRef.current.scrollTop =
        conversationHistoryRef.current.scrollHeight;
    }
  };

  const show = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".button_ai_safe_main2")
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);

      setIsUploading(true); // Start uploading spinner

      // Convert the file into binary (ArrayBuffer)
      const arrayBuffer = await file.arrayBuffer();

      try {
        // Send the file as binary data to the backend
        const response = await serverAxios.aiSafeAxios.post(
          "/upload",
          arrayBuffer,
          {
            headers: {
              "Content-Type": "application/octet-stream",
              "Content-Disposition": `attachment; filename="${file.name}"`,
            },
          }
        );

        if (response.data.success) {
          console.log("File uploaded successfully:", response.data);
          console.log("hello", response.data.data);

          // Once the file is uploaded, show its name in the textarea as ChatGPT would
          setPrompt((prev) => `${prev}\n[File uploaded: ${file.name}]`);
          setIdChat(response.data.data);
          setShowFileUploadPopup(false);
        } else {
          throw new Error(response.data.message || "File upload failed");
        }
      } catch (error) {
        console.error("Error in file upload:", error.message);
        setError("File upload failed. Please try again.");
      } finally {
        setIsUploading(false); // Stop uploading spinner
      }
    }
  };

  return (
    <>
      <div
        className={`ai_safe_sidebar ${sidebarVisible ? "visible" : ""}`}
        ref={sidebarRef}
      >
        <div className="ai_safe_sidebar-content">
          <PrivacySettings
            privacySettings={privacySettings}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>
      </div>

      <div className="main_ai_safe">
        <div className="headingsdfdsf_ai_safe">
          <div className="conversation_history" ref={conversationHistoryRef}>
            {conversationHistory.map((entry, index) => (
              <ConversationEntry key={index} entry={entry} id={id} />
            ))}
          </div>
          {showScrollButton && (
            <button
              className="scroll_to_bottom_button"
              onClick={scrollToBottom}
            >
              <BsArrowDown />
            </button>
          )}
          {hasLanded && (
            <div className="landing_container">
              <div className="heading_ai_safe">{t("heading")}</div>
              <div className="text_ai_safe">{t("heading2")}</div>
              <img
                src={img2}
                className="ai_safe_image"
                alt="image comes here"
              />
              <div className="mid_text_ai_safe">
                {t("line1")}
                <div className="row_ai_safe">
                  <div>{t("line2")}</div>
                  <div>{t("line3")}</div>
                  <div>3. "Adjust prompt before sending (Sep'24 Release)"</div>
                  <div>4. "Chat with multiple LLMs (Oct'24 Release)"</div>
                </div>
              </div>
            </div>
          )}

          {loading && <div className="loading_message">AI is thinking...</div>}
          {error && (
            <div className="error_message">
              <strong>Error:</strong> {error}
            </div>
          )}
        </div>

        <div className="bottom_top_ai_safe">
          <div className="bottom_ai_safe_main">
            <div className="textarea_container">
              <div
                onClick={() => setShowFileUploadPopup(true)}
                className="upload_button "
              >
                <GoPaperclip size={25} />
              </div>
              <textarea
                ref={textareaRef}
                className="input_ai_safe"
                placeholder="Enter your prompt here..."
                value={prompt}
                onChange={(e) => {
                  setPrompt(e.target.value);
                  adjustTextareaHeight();
                }}
                onKeyPress={handleKeyPress}
                rows={1}
                style={{ overflowY: "hidden" }}
              ></textarea>

              {showFileUploadPopup && (
                <div className="file-upload-popup">
                  <div className="popup-content">
                    <h3>Upload your file</h3>
                    {isUploading ? (
                      <div className="circular-loader">
                        {/* You can use a CSS spinner or an icon */}
                        <div className="spinner"></div> {/* CSS loader */}
                        <p>Uploading...</p>
                      </div>
                    ) : (
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e)}
                      />
                    )}
                    <button onClick={() => setShowFileUploadPopup(false)}>
                      Close
                    </button>
                  </div>
                </div>
              )}

              <div>
                <div className="left_20 button_ai_safe_main2" onClick={show}>
                  <img
                    src={img1}
                    alt=""
                    height={35}
                    width={35}
                    className="img_ai_safe"
                  />
                </div>
                <button
                  className="button_ai_safe_main"
                  onClick={handleChat}
                  disabled={loading}
                >
                  <div className="logo_ai_safe_main">
                    <LuSendHorizonal />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainAiSafe;
