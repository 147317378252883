import "./Login.scss";
import React, {useState} from "react";
import logo from "../../logo_maya.png";
import {useForm} from "react-hook-form";
import Helmet from "react-helmet";
import {BiRefresh} from "react-icons/bi";
import {ThreeDots} from 'react-loading-icons'
import {FiEye, FiEyeOff} from 'react-icons/fi';
import useAppContext, {AuthState} from "../../hooks/useAppContext";
import {serverAxios} from "../../utils/AxiosUtils";
import AuthURL from "../../model/auth_url.json"
import {StoragePref} from "../../utils/LocalStorageUtils";
import useRecaptcha from "../../hooks/useRecaptcha";
import {useNavigate} from "react-router-dom";

const {REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE} = process.env;

export default function Login() {
    const {isLoading} = useAppContext();
    const {handleLogin, handleOtpLogin, otpEnabled} = useLogin();
    const {recaptchaImage, recaptchaToken, reloadRecaptcha, recaptchaError } = useRecaptcha();
    const [rememberMe, setRememberMe] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const {
        register, handleSubmit, formState: {errors},
    } = useForm();

    const onSubmit = (data) => {
        handleLogin({...data, rememberMe: rememberMe, captchaToken: recaptchaToken});
    };
    const onSubmitOtp = (data) => {
        handleOtpLogin(data.otp);
    };
    return (<>
        <Helmet>
            <title>Sign in - {REACT_APP_SITE_TITLE} </title>
        </Helmet>
        <div className="login-page">
            <div className="Login text-center ">
                {!otpEnabled ? <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <a href="https://mayadataprivacy.eu/">
                        <img className="mb-4" src={logo} alt="" width="72" height="72"/>
                    </a>
                    {/* <p className="mb-4 updateInfoMessage">
          Site m is planned for upgrade and will not be available on 13th of January 2023 from 11:00 GMT to 17:00 GMT
          </p> */}
                    <div className="form-group">
                        <label htmlFor="font-5 email">Email</label>
                        <input
                            id="email"
                            name="email"
                            className="font-4 form-control"
                            placeholder="Enter Email address"
                            {...register("email", {
                                required: "Email Address is required", pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        <span className="font-6 errorMessage">{errors.email?.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="font-5 password">Password</label>
                        <div className="flex">
                            <div className="pass-icon" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FiEye size={18} color="#667085"/> :
                                    <FiEyeOff size={18} color="#667085"/>}
                            </div>

                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className="font-4 input-field-shadow form-control"
                                placeholder="Enter password"
                                {...register("password", {
                                    required: "Password is required",
                                })}
                            />
                        </div>
                        <span className="errorMessage">{errors.password?.message}</span>
                    </div>

                    <div className="form-group">
                        <label htmlFor="font-5 ">Recaptcha text</label>
                         <div className="flex">
                            <div className="cursor-pointer pass-icon" onClick={reloadRecaptcha}><BiRefresh
                                size={25}/></div>
                            <div className="input-field-shadow justify-content-center align-items-center text-center form-control font-5">
                                {recaptchaImage?<img src={recaptchaImage} alt="Captcha Image"/>:<div className={`font-4 ${(recaptchaError === "Reload Captcha' error.") ? 'text-danger' : ''}`}>{recaptchaError}</div>}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <input
                            id="captchaValue"
                            name="captchaValue"
                            className="font-4 form-control"
                            placeholder="Enter captcha text"
                            {...register("captchaValue", {
                                required: "Captcha text is required", pattern: {
                                    message: "Invalid captcha text ",
                                },
                            })}
                        />
                        <span className="font-6 errorMessage">{errors.captchaValue?.message}</span>
                    </div>
                    <div className="form-group rme-fw">
                        <div className="rme-checkbox">
                            <input
                                type="checkbox"
                                className="font-4 rme-checkbox-input cursor-pointer"
                                id="customCheck1"
                                onChange={(e) => {
                                    setRememberMe(!rememberMe);
                                }}
                            />
                            <label className="font-4 rme-checkbox-label" htmlFor="customCheck1">
                                Remember me
                            </label>
                        </div>
                        <p className="forgot-password">
                            <a href="/forgotPassword" className="font-4">Forgot password?</a>
                        </p>
                    </div>
                    <div className="form-terms-and-conditions">
                        <label className="font-4">
                            By Signing in you accept our{" "}
                            <a
                                href="https://mayadataprivacy.eu/privacy-policy"
                                target="_blank"
                            >
                                <p className="font-4">Terms of use and Privacy Policy</p>
                            </a>
                        </label>
                    </div>

                    <button
                        onClick={handleSubmit}
                        className="font-6 btn btn-dark btn-lg btn-block"
                        disabled={isLoading}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        {isLoading ? <ThreeDots height="20"
                                                width="20"
                                                radius="3" stroke="#98ff98" strokeOpacity={.1}
                                                speed={1}/> : <>Sign in</>}
                    </button>

                    <div className="form-login-register">
                        <label className="font-4">
                            Don`t have Account? <a href="/register" className="font-5">Sign up here</a>
                        </label>
                    </div>
                </form> : <>
                    <form className="login-form" onSubmit={handleSubmit(onSubmitOtp)}>
                        <div className="Otp-screen">
                            <a href="https://mayadataprivacy.eu/">
                                <img className="mb-4" src={logo} alt="" width="72" height="72"/>
                            </a>
                            {/*<p className="font-5">{showMessage && toastMessage}</p>*/}

                            <div className="form-group">
                                <label htmlFor="font-5 otp">OTP*</label>
                                <input
                                    id="otp"
                                    name="otp"
                                    className="font-4 form-control"
                                    placeholder="Enter OTP from email"
                                    {...register("otp", {
                                        required: true,

                                    })}
                                />
                                <span
                                    className="font-6 errorMessage">{errors.lastName?.type === "required" && "last Name is required"}</span>
                            </div>

                            <button
                                onClick={handleSubmit}
                                className="font-6 btn btn-dark btn-lg btn-block"
                                disabled={isLoading}
                                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                            >
                                {isLoading ? <ThreeDots height="20"
                                                        width="20"
                                                        radius="3" stroke="#98ff98" strokeOpacity={.1}
                                                        speed={1}/> : <>Submit OTP</>}
                            </button>
                            <div className="form-login-register">
                                <label className="font-4">
                                    Don`t have Account? <a href="/register" className="font-5">Sign up here</a>
                                </label>
                            </div>
                        </div>
                    </form>
                </>}
            </div>
        </div>
    </>);
}


const useLogin = () => {
    const navigate =    useNavigate();
    const {showToast, updateAuthState, setLoading} = useAppContext();
    const [tempUser, setTempUser] = useState(null);
    const [otpEnabled, setOtpEnabled] = useState(false);
    const handleLogin = ({
                             email, password, captchaToken, captchaValue, rememberMe
                         }) => {
        setLoading(true);
        const loginData = {
            userName: email, password: password, captchaValue: captchaValue, captchaToken: captchaToken
        }
        serverAxios.authAxios.post(AuthURL.login, loginData)
            .then(res => {
                const responseData = res.data;
                if (!responseData.success) {
                    showToast(responseData.message);
                    return;
                }
                const oUser = {
                    ...responseData.data, userName: email, password: password,
                    status: res.status, rememberMe: rememberMe
                };
                const otpEnabled = oUser.isOtpEnable;
                if (otpEnabled) {
                    setOtpEnabled(true);
                    setTempUser(oUser);
                    return;
                }
                StoragePref.user.save(oUser);
                updateAuthState(AuthState.Authenticated);
                // navigate("/newrun")
                // navigate("dashboard/fa")
            }).catch(err => {
                if (err.message.includes("authorization")) {
                    showToast("Invalid UserName Or Password!",'fail')
                } else {
                    showToast(err.message,'fail');
                }
        }).finally(() => {
            setLoading(false);
        })
    }

    const handleOtpLogin = (otp) => {
        if (tempUser === null) {
            return;
        }
        if (!otpEnabled) {
            return;
        }
        setLoading(true);
        const loginData = {
            userName: tempUser.userName,
            password: tempUser.password,
            tokenValue: otp,
            token: tempUser.tokenObj
        }
        serverAxios.authAxios.post(AuthURL.login, loginData)
            .then(res => {
                const responseData = res.data;
                if (!responseData.success) {
                    showToast(responseData.message);
                    return;
                }
                const oUser = {
                    ...responseData.data, userName: tempUser.userName, password: tempUser.password,
                    status: res.status, rememberMe: tempUser.rememberMe
                };
                StoragePref.user.save(oUser);
                setOtpEnabled(false);
                updateAuthState(AuthState.Authenticated);
                // navigate("/landing")
            }).catch(err => {
            showToast(err.message);
        }).finally(() => {
            setLoading(false);
        })
    }
    return {handleLogin, handleOtpLogin, otpEnabled};
}
