import React from "react";
import PropTypes from "prop-types";

const PrivacySettings = ({
  privacySettings,
  handleCheckboxChange,
  closeSidebar,
}) => (
  <div className="">
    <div className="header">
      <h2 className="">Privacy Settings</h2>
      <button className="close-button" onClick={closeSidebar}>
        ×
      </button>
    </div>
    <p className="">Enable Privacy for:</p>
    {Object.keys(privacySettings).map((key) => (
      <div className="switch_body" key={key}>
        <label className="switch">
          <input
            type="checkbox"
            checked={privacySettings[key]}
            onChange={() => handleCheckboxChange(key)}
          />
          <span className="slider round"></span>
        </label>
        <span className="switch_label">{key}</span>
      </div>
    ))}
  </div>
);

PrivacySettings.propTypes = {
  privacySettings: PropTypes.object.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  closeSidebar: PropTypes.func.isRequired,
};

export default PrivacySettings;
