
export const databaseValidation = (source, setFormDataForDataBase, isSource) => {
    const { databaseType, config } = source
    const {
        databaseHost,
        databasePort,
        authType,
        databaseUser,
        databasePassword,
        databaseName,
        schemaName,
        backupSchema,
        databaseMode,
        hanaDbType,
    } = config

    if (databaseHost === '' || databasePort === '') return false
    if (authType === 'PASSWORD') {
        if (databaseUser === '' || databasePassword === '') return false
        if (databaseType === 'HANA' || databaseType === 'ORACLE') {
            if (databaseUser === '') {
                if (schemaName === '') return false
            }

        }
    }
    if (databaseType === 'HANA') {
        if (databaseMode === 'MULTIPLE_CONTAINER' && hanaDbType === 'TENANT') {
            if (databaseName === '') return false
        }
    }



}
export const handleDto = async (setFormDataForDataBase, formDataForDataBase, isSource) => {
    const { sourceConfig, targetConfig } = formDataForDataBase;
    const {
        config: {
            databaseMode = "",
            authType = "",
            hanaDbType = "",
            databaseHost = "",
            databasePort = "",
            databaseUser = "",
            databasePassword = "",
            databaseName = "",
            schemaName = "",
            backupSchema = ""
        } = {},
        databaseType = ""
    } = isSource ? sourceConfig : targetConfig;
    await setFormDataForDataBase(prev => ({
        ...prev,
        [isSource ? 'sourceConfig' : 'targetConfig']: {
            ...prev[isSource ? 'sourceConfig' : 'targetConfig'],
            config: {
                ...prev[isSource ? 'sourceConfig' : 'targetConfig'].config,
                databaseName: databaseType === 'ORACLE' ? (databaseName === '' ? 'XE' : databaseName) : databaseName,
                schemaName: databaseType === 'POSTGRES' ? (schemaName === '' ? 'public' : schemaName) :
                    (authType === 'PASSWORD' && databaseUser != '' && schemaName === '') ? databaseUser : schemaName,
                backupSchema: backupSchema === '' ? databaseType === 'POSTGRES' ? (schemaName === '' ? 'public' : schemaName) :
                    (authType === 'PASSWORD' && databaseUser != '' && schemaName === '') ? databaseUser : schemaName : backupSchema,
            },
        },
    }));
    // await setFormDataForDataBase(prev => ({
    //   ...prev,
    //   [isSource ? 'sourceConfig' : 'targetConfig']: {
    //     ...prev[isSource ? 'sourceConfig' : 'targetConfig'],
    //     config: {
    //       ...prev[isSource ? 'sourceConfig' : 'targetConfig'].config,
    //       databaseName: databaseType === 'ORACLE' ? (databaseName === '' ? 'XE' : databaseName) : databaseName,
    //       backupSchema: backupSchema === '' ? formDataForDataBase.sourceConfig.config.schemaName : backupSchema,
    //     },
    //   },
    // }));

};