import "./Profile.scss";

import React, { useRef } from "react";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../hooks/UserContext";
import { useUserContext } from "../../hooks/UserContext";
import { useForm } from "react-hook-form";
import { FiEdit2, FiEye, FiEyeOff } from "react-icons/fi";
import { TiCancel } from "react-icons/ti";
import { BiSave, BiRefresh } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import useAuth from "../../hooks/useAuth";
import { serverAxios } from "../../utils/AxiosUtils";
import AuthURL from "../../model/auth_url.json";
import { decodeJwt } from "../../utils/Methods";
import { StoragePref } from "../../utils/LocalStorageUtils";
import Popup from "../../components/popupModel/Popup";
import { useNavigate } from "react-router-dom";
import useAppContext, { AuthState } from "../../hooks/useAppContext";
import useRecaptcha from "../../hooks/useRecaptcha";
import { isEditable } from "@testing-library/user-event/dist/utils";

const Profile = () => {
  const formRef = useRef(null);
  const { recaptchaImage, recaptchaToken, reloadRecaptcha, recaptchaError } =
    useRecaptcha();
  const { resetPasswordVerifyInfo, resetPasswordOTPVerify, resetNewPassword } =
    useAuth();
  const { updateAuthState } = useAppContext();
  const [showPassword, setShowPassword] = useState(false);
  const { isLoading, setLoading } = useContext(UserContext);
  const { showToast } = useUserContext();
  const [resetPassword, setResetPassword] = useState(false);
  const [decodedValue, setDecodedValue] = useState({});
  const { setUserContext } = useAuth();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      profileData: {
        firstName: "",
        lastName: "",
        userMobileNo: "",
        userEmailId: "",
        recaptchaValue: "",
        otpFromEmail: "",
        newpassword: "",
      },
    },
  });
  useEffect(() => {
    getUserData();
  }, []);
  const [token, SetToken] = useState("");
  const getUserData = async () => {
    const oUser = StoragePref.user.load();
    const decodedValue = decodeJwt(oUser.jwtToken);
    setDecodedValue(decodedValue);
    SetToken(oUser.jwtToken);

    setValue("profileData", {
      firstName: decodedValue.fname,
      lastName: decodedValue.lname,
      userMobileNo: decodedValue.mob || "",
      userEmailId: decodedValue.preferred_username,
    });
  };

  // ***** Post data  *******
  const onSaveChangesPress = (e) => {
    e.preventDefault();
    setLoading(true);
    let profileData = getValues("profileData");
    const data = {
      firstName:
        profileData.firstName !== decodedValue.fname
          ? profileData.firstName
          : null,
      lastName: profileData.lastName,
      userMobileNo:
        profileData.userMobileNo !== decodedValue.mob
          ? profileData.userMobileNo
          : null,
      userEmailId: profileData.userEmailId,
    };
    serverAxios.authAxios
      .post(AuthURL.profile_update, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          console.error("There was an error when saving profile data!");
          showToast("There was an error when saving profile data!", "warning");
        } else {
          handleOpenPopup(
            "After updating your profile, you will need to re-login once. Would you like to do it now?"
          );
          showToast(
            res.data.message === "fetched"
              ? "Profile Data has been saved!"
              : res.data.message
          );
        }
      })
      .catch((oError) => {
        setLoading(false);
        if (oError.message) {
          const errorMessage = oError.message;
          if (errorMessage.endsWith("cannot be trusted")) {
            showToast("Unauthorized: Source cannot be trusted", "warning");
          } else {
            showToast(oError.message, "warning");
          }
        } else {
          showToast(
            "We apologize, something seems to be amiss at the moment. Please try again later. Thank you for your patience.",
            "warning"
          );
        }
        console.error("There was an error!", oError.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const handleResetPass = () => {
  //   setResetPassword(true);
  //   showToast("Please remember to click the 'Save Changes' button to ensure your data is saved!");
  // }
  const [showPopup, setShowPopup] = useState(false);
  const [popupText, setPopupText] = useState("");
  const handleOpenPopup = (text) => {
    setPopupText(text);
    setShowPopup(true);
  };
  const logoutSec = () => {
    StoragePref.clear();
    updateAuthState(AuthState.Unauthenticated);
    navigate("/login");
  };
  const handleConfirm = (result) => {
    if (result) {
      logoutSec();
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  };
  const [wantToResetPassword, setWantToResetPassword] = useState(false);
  const [wantToVerifyCaptcha, setWantToVerifyCaptcha] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [resetPassInfo, setResetPassInfo] = useState({
    otpKey: "",
    smsToken: "",
  });
  //reset password logic
  const handleResetPass = (e) => {
    e.preventDefault();
    setWantToResetPassword(true);
  };
  const [timer, setTimer] = useState(1);
  const handleVerifyCaptcha = () => {
    let profileData = getValues("profileData");
    const data = {
      email: profileData.userEmailId,
      recaptchaToken: recaptchaToken,
      captchaValue: profileData.recaptchaValue,
    };
    resetPasswordVerifyInfo({ ...data }, fnSuccess, fnError, setTimer);
  };
  const fnSuccess = (key, smsToken) => {
    setWantToVerifyCaptcha(true);

    setResetPassInfo({
      otpKey: key,
      smsToken: smsToken,
    });
  };
  const fnError = (err) => {
    showToast(err, "warning");
  };
  const handleVerifyOTP = () => {
    let profileData = getValues("profileData");
    const data = {
      otpKey: resetPassInfo.otpKey,
      smsToken: resetPassInfo.smsToken,
      otp: profileData.otpFromEmail,
    };

    resetPasswordOTPVerify({ ...data }, fnSuccessOtp);
  };

  const handleSavePassword = () => {
    let profileData = getValues("profileData");
    const data = {
      newPassword: profileData.newpassword,
      otpKey: resetPassInfo.otpKey,
      smsToken: resetPassInfo.smsToken,
    };
    resetNewPassword({ ...data }, fnSuccessNewPassword);
  };
  const fnSuccessNewPassword = () => {
    setWantToVerifyCaptcha(false);
    setNewPassword(false);
    setWantToResetPassword(false);
  };
  const fnSuccessOtp = (key, smsToken) => {
    setResetPassInfo({
      otpKey: key,
      smsToken: smsToken,
    });
    setNewPassword(true);
  };
  const handleCancel = () => {
    getUserData();
    setWantToResetPassword(false);
    setWantToVerifyCaptcha(false)
    setNewPassword(false)
  };
  return (
    <>
      <div className="first_section my-responsive-div">
        {showPopup && (
          <div className="backdropPopupStyle">
            <Popup
              popupTitle={"Alert!"}
              popupMessage={popupText}
              isConfirm={handleConfirm}
            />
          </div>
        )}
        {/* <div className="header-title"> */}
        <div className="header"></div>
        <div className="data">
          <div className="User_profile ">
            <div className="user">
              <div className="profile_photo col">
                <div className="profile_image_wrapper">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRhdai_J00wHqZKuhbsgMax1uRNi05fMmavm2lwqiz-Umw3OvnOXZaNf_VqLsnfyeVfj9g&usqp=CAU"
                    id="imageProfile"
                    className="rounded-circle profileimg"
                    alt="Profile"
                  />
                  <div className="overlay" style={{ borderRadius: "50%" }}>
                    <div className="editAvatar">
                      <FiEdit2></FiEdit2> Edit
                    </div>
                  </div>
                </div>
              </div>
              <div className="user_dis col">
                <div className="font-5 user_name">
                  {decodedValue.fname} {decodedValue.lname}
                </div>
                <div className="font-4 user_email">
                  {decodedValue.preferred_username}
                </div>
              </div>
            </div>
            <div className="User_action col">
              <div className="Cancelm">
                <div className="d-grid gap-2 d-md-flex  justify-content-md-end">
                  <button
                    type="button"
                    className="font-6 btn-low-purple me-md-2"
                    disabled={isLoading}
                    onClick={() => handleCancel()}
                  >
                    <TiCancel size={18} /> Cancel
                  </button>
                  <button
                    className="font-6 btn-purple"
                    type="button"
                    onClick={onSaveChangesPress}
                    disabled={isLoading}
                  >
                    <BiSave /> Save Changes
                  </button>
                </div>
              </div>
              <div className="save_as"></div>
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="regi">
          <div className="row Registration_process">
            <div className="col-4">
              <div className="font-5 content1">Account Information</div>
              <div className="font-4 description">
                Update your photo and personal details
              </div>
            </div>
            <div className="col-8 edit_form container">
              <form
                ref={formRef}
                className="edit_form_inner"
                onSubmit={handleSubmit(onSaveChangesPress)}
              >
                <div className="mb-3">
                  <label
                    htmlFor=" profile_emailAddress"
                    className="font-5 form-label"
                  >
                    Email address
                  </label>
                  <div className="input-field-shadow">
                    {/* <span className="input-group-text" id="basic-addon1">
                      <HiOutlineMail></HiOutlineMail>
                    </span> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M22 6C22 4.9 21.1 4 20 4H4C2.9 4 2 4.9 2 6M22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6M22 6L12 13L2 6"
                        stroke="#101828"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <input
                      type="email"
                      className="font-4 remove-input-style no-outline "
                      style={{ background: "transparent" }}
                      id="profile_emailAddress"
                      placeholder="name@example.com"
                      aria-describedby="basic-addon1"
                      name="userEmailId"
                      {...register("profileData.userEmailId", {
                        message: "Email address is not editable",
                      })}
                      disabled={true}
                    />

                    <span className="font-6 errorMessage">
                      {errors.userEmailId?.message && "last Name is required"}
                    </span>
                  </div>
                </div>
                <div className="  ">
                  <div className="mb-3 row ">
                    {/* <div className="col-9 ">
                      <label htmlFor=" profilePasswordInput" className="font-5 form-label">
                        Password
                      </label>
                      <div className="input-group ">
                        <span className="input-group-text" id="basic-addon2">
                          <RiLockPasswordLine></RiLockPasswordLine>
                        </span>
                        <div className="flex input-field-shadow-p">
                          <div className="pass-icon" onClick={() => { setShowPassword(!showPassword) }}>{showPassword ? <FiEye size={18} color="#667085" /> : <FiEyeOff size={18} color="#667085" />}</div >


                          <input
                            type={showPassword ? "text" : "password"}
                            id="profilePasswordInput"
                            className="font-4  input-field-shadow"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="basic-addon2"
                            name="password"
                            {...register("profileData.password", {
                              required: "Password is required",
                              pattern: {
                                value:
                                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-$*.{}?"!@#%&\/\\,><':;|_~`^\]\[\)\(])\S{8,}$/,
                              },
                            })}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="w-100 passwordResetButtonContainerm ">
                      <div className=" ">
                        <label
                          htmlFor=" profilePasswordInput"
                          className="font-5 form-label"
                        >
                          Password
                        </label>
                        {!wantToResetPassword && (
                          <>
                            <div className="w-100 d-flex gap-3">
                              <div className="w-100">
                                <input
                                  type={"password"}
                                  id="profilePasswordInput"
                                  className="font-4  input-field-shadow"
                                  placeholder="Password"
                                  aria-label="Password"
                                  aria-describedby="basic-addon2"
                                  name="password"
                                  // {...register("profileData.password", {
                                  //   required: "Password is required",
                                  //   pattern: {
                                  //     value:
                                  //       /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-$*.{}?"!@#%&\/\\,><':;|_~`^\]\[\)\(])\S{8,}$/,
                                  //   },
                                  // })}
                                  {...register("profileData.newpassword", {
                                    required: true,
                                  })}
                                />
                              </div>
                              <button
                                type="button"
                                className=" btn-low-purple font-6 "
                                onClick={handleResetPass}
                              >
                                Reset Password
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${wantToResetPassword && "border border-gry p-3"
                      } mb-3 `}
                  >
                    {newPassword ? (
                      <>
                        <label className="font-5 form-label">
                          New Password
                        </label>
                        <div className="d-flex justify-content-between row">
                          <div className="col-7 form-group">
                            <input
                              id="newpassword"
                              name="newpassword"
                              className=" font-4 input-field-shadow "
                              placeholder="Type New Password "
                              {...register("profileData.newpassword", {
                                required: true,
                              })}
                            />
                          </div>

                          <button
                            type="button"
                            className=" col-5 btn-low-purple font-6 "
                            onClick={handleSavePassword}
                          >
                            Save Password
                          </button>
                        </div>
                      </>
                    ) : wantToVerifyCaptcha ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="font-5 otp"
                            className="font-5 form-label"
                          >
                            OTP*
                          </label>
                          <div className="d-flex justify-content-between row">
                            <div className="col-7">
                              <input
                                id="otpFromEmail"
                                name="otpFromEmail"
                                className="col-7 font-4 input-field-shadow "
                                placeholder="Enter OTP from email"
                                {...register("profileData.otpFromEmail", {
                                  required: true,
                                })}
                              />
                            </div>

                            <button
                              type="button"
                              className=" col-5 btn-low-purple font-6 py-3 "
                              onClick={handleVerifyOTP}
                            >
                              Verify OTP
                            </button>
                          </div>
                        </div>
                      </>
                    ) : wantToResetPassword ? (
                      <>
                        <div className="form-group">
                          <label
                            htmlFor="font-5 "
                            className="font-5 form-label"
                          >
                            Recaptcha text
                          </label>
                          <div className=" d-flex justify-content-between row">
                            <div className=" flex col-5">
                              <div
                                style={{ right: "5%" }}
                                className="cursor-pointer pass-icon"
                                onClick={reloadRecaptcha}
                              >
                                <BiRefresh size={25} />
                              </div>
                              <div className="input-field-shadow input-field-shadow font-5">
                                {recaptchaImage ? (
                                  <img
                                    src={recaptchaImage}
                                    alt="Captcha Image"
                                  />
                                ) : (
                                  <div
                                    className={`font-4 ${recaptchaError ===
                                        "Reload Captcha' error."
                                        ? "text-danger"
                                        : ""
                                      }`}
                                  >
                                    {recaptchaError}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-4 form-group">
                              <input
                                id="recaptchaValue"
                                name="recaptchaValue"
                                className="font-4 input-field-shadow"
                                placeholder="Enter captcha text"
                                {...register("profileData.recaptchaValue", {
                                  required: "Captcha text is required",
                                })}
                              />
                              <span className="font-6 errorMessage">
                                {errors.recaptchaValue?.message}
                              </span>
                            </div>
                            <button
                              type="button"
                              className="btn-low-purple  col-3 font-6"
                              onClick={handleVerifyCaptcha}
                            >
                              Verify Captcha
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6">
                    <label
                      htmlFor="profile_FirstNameInput"
                      className="font-5 form-label"
                    >
                      First Name
                    </label>
                    <input
                      type="text"
                      id="profile_FirstNameInput"
                      className="font-4 input-field-shadow"
                      placeholder="First name"
                      aria-label="First name"
                      name="firstName"
                      {...register("profileData.firstName")}
                    />
                  </div>
                  <div className="col-6">
                    <label htmlFor="last_name" className="font-5 form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      className="font-4 input-field-shadow"
                      placeholder="Last name"
                      aria-label="Last name"
                      name="lastName"
                      {...register("profileData.lastName")}
                    />
                  </div>
                </div>
                {/* <div className="mb-3">
                  <label
                    htmlFor="profile_DisplayNameInput"
                    className="font-5 form-label"
                  >
                    Display Name
                  </label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon3">
                      <BsPerson></BsPerson>
                    </span>
                    <input
                      type="text"
                      className="font-4 input-field-shadow"
                      id="profile_DisplayNameInput"
                      placeholder="Here goes Display Names"
                      aria-describedby="basic-addon3"
                      name="displayName"
                      {...register("profileData.displayName")}
                    />
                  </div>
                </div> */}
                <div className="mb-3">
                  <label
                    htmlFor="profile_userMobileNoInput"
                    className="font-5 form-label"
                  >
                    Mobile No
                  </label>
                  <input
                    type="text"
                    className="font-4 input-field-shadow"
                    id="profile_userMobileNoInput"
                    placeholder="Here goes userMobileNo"
                    name="userMobileNo"
                    {...register("profileData.userMobileNo", {
                      pattern: /^[A-Za-z0-9\s]+$/,
                    })}
                  />
                </div>
                <div className="font-5 text-blue ">
                  To delete your account and all your data, send an email to
                  <span>
                    <a href="mailto:support@mayadataprivacy.eu">
                      support@mayadataprivacy.eu
                    </a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
