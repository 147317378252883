import { serverAxios } from "../../utils/AxiosUtils";

export async function getExecutionSetData() {
  const response = await serverAxios.appSafeAxios.get("execution-set-details");
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}
export async function getUtilitySetData() {
  const response = await serverAxios.appSafeAxios.get("utility-set");
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}
export async function getConfigSetData() {
  const response = await serverAxios.appSafeAxios.get("config-set");
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}
export async function getExecutionSetDataById(id) {
  const response = await serverAxios.appSafeAxios.get(
    `execution-set-details/${id}`
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data;
}
export async function deleteTableInUtilitySet(encodedUtilityId, tableId) {
  const response = await serverAxios.appSafeAxios.post(
    `utility-set/${encodedUtilityId}/tables/${tableId}/delete`,
    {
      "content-type": "application/json",
    }
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.message;
}
export async function handleDeleteRowOfConditionTable(
  encodedUtilityId,
  conditionId
) {
  const response = await serverAxios.appSafeAxios.post(
    `utility-set/${encodedUtilityId}/conditions/${conditionId}/delete`,
    {
      "Content-Type": "application/json",
    }
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.message;
}
export async function getConditionTable(encodedUtilityId) {
  const response = await serverAxios.appSafeAxios.get(
    `utility-set//${encodedUtilityId}/conditions`,
    {
      "Content-Type": "application/json",
    }
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}
export async function getTopTable(encodedUtilityId) {
  const response = await serverAxios.appSafeAxios.get(
    `utility-set/${encodedUtilityId}/tables`
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}
export async function getUtilityForUpadte(utilityId) {
  const response = await serverAxios.appSafeAxios.get(
    `utility-set/${utilityId}`
  );
  if (!response.data.success) {
    throw new Error(response.data);
  }
  return response.data.data;
}

export async function getFileGroups() {
  const res = await serverAxios.fileSafeAxios.get("file/groups");

  if (res.data.data.length < 1) {
    createGroup("Default", false);
    createGroup("Group - 1", false);
    createGroup("Group - 2", true);
    return;
  }
  let aSortedGroups = res.data.data.sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0
  );

  if (!res.data.success) {
    throw new Error(res.data);
  }
  return aSortedGroups;
}
export async function createGroup() {
  const res = await serverAxios.fileSafeAxios.post("file/groups");
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}

export async function getAllJObs() {
  const res = await serverAxios.appSafeAxios.get("jobs");
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
export async function deleteBackup(dto) {
  const res = await serverAxios.appSafeAxios.post("/backup/delete", dto);

  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data;
}

export async function getJobsByID(JOB_ID) {
  const res = await serverAxios.appSafeAxios.get(`jobs/${JOB_ID}`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
export async function redoBackup(dto) {
  const res = await serverAxios.appSafeAxios.post("/backup/redo", dto);

  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data;
}

export async function getJobStepTables(STEP_ID) {
  const res = await serverAxios.appSafeAxios.get(`job-steps/${STEP_ID}/tables`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
export async function getColumnOfJob(STEP_ID, JOB_TABLE_ID) {
  const res = await serverAxios.appSafeAxios.get(
    `job-steps/${STEP_ID}/tables/${JOB_TABLE_ID}`
  );
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
export async function uploadFileInUtilitySet(formData) {
  const res = await serverAxios.appSafeAxios.post(
    "utility-set/upload/utility-table-excel",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data;
}
// **** Hitory for Backup and Restore ******
export async function getBackupHistory(key) {
  const res = await serverAxios.appSafeAxios.get(`backup/${key}`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
// *********utility params and Condition ************
export async function getUtilityParam() {
  const res = await serverAxios.appSafeAxios.get(`utility-set/utility-params`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
// ******** PII Utility History ************
export async function getUtilityPiiHistory() {
  const res = await serverAxios.appSafeAxios.get(`auto-utility`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
// ******** PII Utility History by Id ************
export async function getUtilityPiiHistoryById(id) {
  const res = await serverAxios.appSafeAxios.get(`auto-utility/tables/${id}`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}

// ********get PII setting details ************
export async function getPiiSettingDetails() {
  const res = await serverAxios.appSafeAxios.get(`pii`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
// ********get schema Based Utility Set  ************
export async function getSchemaBasedUtilitySet() {
  const res = await serverAxios.appSafeAxios.get("auto-utility-schema/getAll");
  if (!res.data.success) {
    throw new Error(res.data);
  }
  console.log("response:: ");
  return res.data.data;
}

// ********Collaboration group ************
export async function getCollaborationGroups() {
  const res = await serverAxios.collaborationAxios.get(
    "/project/all-projects",
    {
      "content-type": "application/json",
    }
  );
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}
export async function getSeed({ id }) {
  const res = await serverAxios.collaborationAxios.get(`/project/seed/${id}`, {
    "content-type": "application/json",
  });
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}

export async function createCollaborationGroup({
  projectName,
  projectDescription,
  businessArea,
  industryArea,
  projectAttributes,
}) {
  const res = await serverAxios.collaborationAxios.post(
    "/project/create-project",
    {
      projectName,
      projectDescription,
      businessArea,
      industryArea,
      projectAttributes,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  if (!res.data.success) {
    throw new Error(res.data);
  }

  return res.data;
}
// ********get tables for Data Cleansing  ************
export async function getTablesNameDetails(id) {
  const res = await serverAxios.appSafeAxios.get(`data-cleansing/get-tables/${id}`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}


// ********get column name  using tableName and configSet ************
export async function getColumnData(dto){
  const res = await serverAxios.appSafeAxios.post("data-cleansing/get-columns", dto);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}

//********** post add Condition Data in data cleansning */
export async function postConditionDataInCleaning(dto) {
  const res = await serverAxios.appSafeAxios.post("data-cleansing/create", dto);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}

//******PII Failed Reason **** */
export async function getUtilityPiiFailedReason(id) {
  const res = await serverAxios.appSafeAxios.get(`data-cleansing/pii/${id}`);
  if (!res.data.success) {
    throw new Error(res.data);
  }
  return res.data.data;
}