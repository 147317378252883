import React, { useEffect, useState } from 'react'
import { getUtilityPiiHistory, getUtilityPiiHistoryById } from '../../../hooks/apis/useAppSafeApi';
import TablePro from '../../../components/newTable/TablePro';
import useAppContext from '../../../hooks/useAppContext';
import DetailTablesData from './DetailTablesData';
import { FiEye } from 'react-icons/fi';

const PiiHistory = () => {
    const [piiHistory, setPiiHistory] = useState([])
    const [currentTableForViewDetails, setCurrentTableForViewDetails] = useState({id:"", name:"" })
    const { popupModel } = useAppContext();

    const getUserPiiHistory = async () => {
        await getUtilityPiiHistory().then(res => {
            console.log(res)
            setPiiHistory(res)
        })
    }
    useEffect(() => {
        getUserPiiHistory();
    }, []);
    const getDetailsData = (id) => {
        
    }
    const piiColumn = [
        {
            name: 'Run Id',
            field: 'runId',
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return <div className='cursor-pointer font-5' onClick={async () => await setCurrentTableForViewDetails({ id: item.utilitySetId, name: item.utilitySetName})}>{item.runId}</div>
            }
        },
        {
            name: 'UtilitySet Name',
            field: 'utilitySetName',
            type: 'text',
            width: '5%'
        },
        
        {
            name: 'ConfigSet Name',
            field: "configSetName",
            type: "text",
            width: '5%'
        },
        
        
        {
            name: 'Schema Name',
            field: 'schemaName',
            type: 'text',
            width: '5%'
        },
        
        {
            name: 'Created At',
            field: 'createdDate',
            type: 'date',
            width: '5%'
        },
        {
            name: 'Description',
            field: 'utilitySetDescription',
            type: 'text',
            // width: '5%'
        },
        {
            name:"Action",
            type: "customControl",
            width: "5%",
            fnRenderCustomControl: (item) => {
                return <div className='cursor-pointer font-5' onClick={async () => await setCurrentTableForViewDetails({ id: item.utilitySetId, name: item.utilitySetName })}><FiEye/></div>
            }
        }
    ]
    return (<>
        {currentTableForViewDetails.id !=="" && (
            <div className="backdropPopupStyle">
                <DetailTablesData data={currentTableForViewDetails} onCancel={setCurrentTableForViewDetails}/>
            </div>
        )}
        <TablePro columns={piiColumn} data={piiHistory.sort(
            (a, b) =>
                new Date(b.createdDate) - new Date(a.createdDate)
        )} minRows={5} 
        showRecords={true}
        />
    </>
    )
}

export default PiiHistory