import {Navigate, Outlet, useLocation} from 'react-router-dom'
import useAppContext, {AuthState} from "../../hooks/useAppContext";

const AuthRoot = () => {
    const {authState} = useAppContext();
    const location = useLocation();
    if (authState !== AuthState.Unauthenticated) {
        const from = (location.state?.from?.pathname === '/' || location.state?.from?.pathname===undefined) ? "/landing" : location.state?.from?.pathname
        console.log(from)
        return <Navigate to={from} replace/>
    }
    return (
        <main>
            <Outlet/>
        </main>
    )
}
export default AuthRoot