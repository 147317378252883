import React, { useEffect, useRef, useState } from "react";
import { AppContext, AuthState } from '../hooks/useAppContext';
import { StoragePref } from "../utils/LocalStorageUtils";
import { serverAxios } from "../utils/AxiosUtils";
import { decodeJwt } from "../utils/Methods";
import Toast from "../components/toast/Toast";
import BusySpinner from "../components/busySpinner/BusySpinner";
import useAuth from "../hooks/useAuth";
import Popup from "../components/popupModel/Popup";
import Timer from "../components/Timer/Timer";

export default function AppContextProvider({ children }) {
    let isTokenRefreshing;
    const { handleRefreshApi, handleLogOutApi, refreshBeforeExp } = useAuth();
    const [authState, setAuthState] = useState(AuthState.loading);
    const [isLoading, setIsLoading] = useState(false);
    const [toastShow, setToastShow] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupForRefresh, setShowPopupForRefresh] = useState(false);
    const [popupText, setPopupText] = useState('');
    const [popupTitle, setPopupTitle] = useState('');
    const [toastMessageType, setToastMessageType] = useState('');
    const [confirmButtonLabel, setConfirmButtonLabel] = useState('');
    const [myFunctionOnClickConfirm, setMyFunctionOnClickConfirm] = useState(null);
    const refreshTimeout = useRef(null);
    const handleAfterSessionExpire = async (refreshToken, jwtToken) => {
        setAuthState(AuthState.Unauthenticated);
        handleLogOutApi(refreshToken, jwtToken);
        serverAxios.updateToken(null);
        sessionStorage.removeItem("info");
        localStorage.removeItem("info");
        window.location.reload();
    }

    let isRefreshing = false;
    const checkUserAuthState = async () => {
        try {
            const oUser = StoragePref.user.load();
            if (!oUser) {
                serverAxios.updateToken(null);
                setAuthState(AuthState.Unauthenticated);
                return;
            }
            const decoded = decodeJwt(oUser.jwtToken);
            const exp = await (decoded.exp * 1000);
            const oneMinuteBefore = exp - 60 * 1000;
            const refreshExpTime = new Date(decoded.refresh_token_exp);
            const now = new Date();

            if (refreshExpTime < now) {
                if (oUser && oUser.refreshToken) {
                    await popupModel("Alert", "Your session has expired. Please log in again.", "Re-login", () => handleAfterSessionExpire(oUser.refreshToken, oUser.jwtToken));
                    setAuthState(AuthState.Unauthenticated);
                    handleLogOutApi(oUser.refreshToken, oUser.jwtToken);
                    serverAxios.updateToken(null);
                    sessionStorage.removeItem("info");
                    localStorage.removeItem("info");
                    return;
                }
            } else {

                isTokenRefreshing = false;
                const tokenRefreshInterval = oneMinuteBefore - now;
                const autoLogoutInterval = (refreshExpTime.getTime() - now.getTime()) - ( 60 * 1000);
                const timeUntilPopup = refreshExpTime.getTime() - now.getTime() - (5 * 60 * 1000); // 5 minutes before refreshExpTime

                if (timeUntilPopup > 0 && oUser && oUser.refreshToken) {
                    setTimeout(async () => {
                        await popupModel("Warning", <div className="align-items-center"> Your session is about to expire in <Timer duration={300} onTimeout={() => console.log('Timer expired!')} />. Do you wish to continue?</div>, 'Continue', () => refreshBeforeExp(oUser.refreshToken));
                    }, timeUntilPopup);
                }
                if (!isRefreshing && tokenRefreshInterval > 0 && refreshExpTime > now) {
                    isRefreshing = true
                    setTimeout(async () => {
                        try {
                            isRefreshing = false
                            await handleRefreshApi(oUser.refreshToken);
                        } catch (error) {
                            isRefreshing = false
                            console.error("Error refreshing token:", error);
                        }
                    }, tokenRefreshInterval);
                } else {
                    console.error('The target time has already passed or is too close.');
                }


                await serverAxios.updateToken(oUser.jwtToken);
                setAuthState(AuthState.Authenticated);
            }
        } catch (err) {
            console.error("Error checking user authentication state:", err);
            setAuthState(AuthState.Unauthenticated);
            serverAxios.updateToken(null);
        }
    };

    const messageBeforLogout = (intervalTime) => {
        setTimeout(() => {
            showToast("The token is about to expire in 1 minute. You can re-login, or the screen will automatically log out after 2 minutes.");
        }, intervalTime);
    };

    useEffect(() => {
        checkUserAuthState();
        const checkInterval = setInterval(checkUserAuthState, 60 * 1000);

    }, []);

    const updateState = (state) => {
        if (state === AuthState.Authenticated) {
            checkUserAuthState();
        }
        setAuthState(state);
    };

    const hideToast = () => {
        setToastShow(false);
    };

    const showToast = (message, type) => {
        setToastShow(true);
        setToastMessage(message);
        setToastMessageType(type);
    };

    const popupModel = async (title, message, confirmButtonLabel, onConfirmClick) => {
        setShowPopup(true);
        setPopupTitle(title);
        setPopupText(message);
        setConfirmButtonLabel(confirmButtonLabel);
        setMyFunctionOnClickConfirm(() => onConfirmClick);
    };

    const handleConfirm = (result) => {
        if (result && myFunctionOnClickConfirm) {
            myFunctionOnClickConfirm();
        }
        setMyFunctionOnClickConfirm(null);
        setShowPopup(false);
    };

    return (
        <AppContext.Provider value={{
            authState: authState, updateAuthState: updateState,
            loading: isLoading, setLoading: setIsLoading, showToast, popupModel,
        }}>
            <Toast message={toastMessage} show={toastShow} hideToast={hideToast} type={toastMessageType} />
            {showPopup && (
                <div className='backdropPopupStyle'>
                    <Popup
                        popupTitle={popupTitle}
                        popupMessage={popupText}
                        isConfirm={handleConfirm}
                        confirmButtonLabel={confirmButtonLabel}
                    />
                </div>
            )}
            {children}
            {isLoading && <BusySpinner className={'z-100'} />}
        </AppContext.Provider>
    );
}
