import "./Register.scss";
import React, {useState} from "react";
import logo from "../../logo_maya.png";
import {useForm} from "react-hook-form";
import Helmet from "react-helmet";
import {ThreeDots} from 'react-loading-icons'
import {FiEye, FiEyeOff} from 'react-icons/fi';
import useAppContext from "../../hooks/useAppContext";
import {useNavigate} from "react-router-dom";
import {serverAxios} from "../../utils/AxiosUtils";
import AuthURL from "../../model/auth_url.json";

const {REACT_APP_SITE_TITLE} = process.env;

export default function Register() {
    const {showToast, isLoading} = useAppContext();
    const [showPassword, setShowPassword] = useState(false);
    const registerUser = useRegister();
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const onSubmit = (data) => {
        registerUser({...data}).catch((data) => {
            if (data === "{userMobileNo={mobileNo.invalid}}") {
                showToast("Mobile no. is invalid", 'fail')
            } else {
                showToast(data,'fail');
            }


        });
    };
    const handleInputChange = (e) => {
        try {
            e.target.value = e.target.value.replace(/\s+/g, '');
        } catch (error) {
            console.error('Error handling input:', error);
        }
    };

    return (
        <>
            <Helmet>
                <title>Register - {REACT_APP_SITE_TITLE} </title>
            </Helmet>
            <div className="SignUp text-center ">

                <form onSubmit={handleSubmit(onSubmit)}>
                    <a className="maya-logo-register" href="https://mayadataprivacy.eu/">
                        <img className="mb-2" src={logo} alt="" width="72" height="72"/>
                    </a>
                    <h3 className="font-5 mb-3">Create Your Account</h3>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="userEmailID">Email *</label>
                        <input
                            id="userEmailID"
                            name="userEmailID"
                            className=" form-control font-4  "
                            placeholder="Enter Email address"
                            {...register("userEmailID", {
                                required: "Email Address is required",
                                pattern: {
                                    value:
                                        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: "Invalid email address",
                                },
                            })}
                        />
                        <span className="errorMessage">{errors.userEmailID?.message}</span>
                    </div>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="firstName">First Name *</label>
                        <input
                            id="firstName"
                            name="firstName"
                            className="form-control font-4 "
                            placeholder="Enter First Name"
                            {...register("firstName", {required: true})}
                        />
                        <span className="errorMessage">
              {errors.firstName?.type === "required" && "First Name is required"}
            </span>
                    </div>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="lastName">Last Name</label>
                        <input
                            id="lastName"
                            name="lastName"
                            className="form-control font-4 "
                            placeholder="Enter Last Name"
                            {...register("lastName")}
                        />
                       
                    </div>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="userMobileNo">Mobile Number *</label>
                        <input
                            id="userMobileNo"
                            name="userMobileNo"
                            className="form-control font-4 "
                            placeholder="Phone Number with Country Code (e.g, +1234567890)"
                            onInput={handleInputChange}
                            {...register("userMobileNo", {required: "Mobile No. is required",
                                pattern: {
                                    value: /^\+\d{1,3}\d{8,12}$/,
                                    message: `Mobile numbers should be in the format +<countryCode><number> with no spaces.`,
                                
                                }
                            })}
                        />
                        <span className="errorMessage">
              {errors.userMobileNo && errors.userMobileNo.message}
            </span>
                    </div>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="organizationDetails">Organization Detail *</label>
                        <input
                            id="organizationDetails"
                            name="organizationDetails"
                            className="form-control font-4 "
                            placeholder="Enter Organization Detail"
                            {...register("organizationDetails", {required: true})}
                        />
                        <span className="errorMessage">
              {errors.organizationDetails?.type === "required" && "Organization Detail is required"}
            </span>
                    </div>
                    <div className="form-group">
                        <label className="level-text font-5" htmlFor="password">Password *</label>
                        <div className="flex">
                            <div className="pass-icon" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <FiEye size={18} color="#667085"/> :
                                    <FiEyeOff size={18} color="#667085"/>}
                            </div>

                            <input
                                id="password"
                                name="password"
                                type={showPassword ? "text" : "password"}
                                className="form-control font-4 "
                                placeholder="Enter Password"
                                {...register("password", {
                                    required: "Password is required",
                                    pattern: {
                                        value:
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\-$*.{}?"!@#%&\/\\,><':;|_~`^\]\[\)\(])\S{8,}$/,
                                    },
                                })}
                            />
                        </div>
                        {errors.password && (
                            <span className="errorMessage">
                <span>
                  <span>Your password needs to:</span>
                  <p className="errorMsgListItem">
                    * Include both lower and upper case character
                  </p>
                  <p className="errorMsgListItem">
                    * Include at least one number and symbol
                  </p>
                  <p className="errorMsgListItem">
                    * Be at least 8 characters long
                  </p>
                </span>
              </span>
                        )}
                    </div>
                    <div className="form-terms-and-conditions ">
                        <label className="level-text font-5">
                            By Signing up you accept our{" "}
                            <a
                                href="https://mayadataprivacy.eu/privacy-policy"
                                target="_blank"
                            >
                                <p>Terms of use and Privacy Policy</p>
                            </a>
                        </label>
                    </div>

                    <button
                        className="btn btn-dark btn-lg btn-block font-5"
                        disabled={isLoading}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        {isLoading ? <ThreeDots height="20"
                                                width="20"
                                                radius="3" stroke="#98ff98" strokeOpacity={.1} speed={1}/> : <>Sign
                            Up</>}
                    </button>
                    <div>
                        <label className="level-text font-5">
                            Have an Account? <a href="/login">Sign in</a>
                        </label>
                    </div>
                </form>
            </div>
        </>
    );
}


const useRegister = () => {
    const navigate = useNavigate();
    const {showToast, setLoading} = useAppContext();

    return (data) => {
        const {firstName, lastName, userEmailID, userMobileNo, password, organizationDetails} = data;
        // const mailNickname = emailProcessor(userEmailId);
        const fornData = {
            firstName: firstName,
            lastName: lastName,
            userEmailId: userEmailID,
            passCode: password,
            userMobileNo: userMobileNo,
            organizationDetails: organizationDetails,
            status: "ACTIVE",
            // roles: ["normal_user"]
        }
        // setLoading(true);
        setLoading(true);
        return serverAxios.authAxios
            // .post("/mdp/api/user", {
            .post(AuthURL.register, fornData, {
                    headers: {
                        'content-type': 'application/json'
                    }
                }
            )
            .then((res) => {
                showToast("CONGRATULATIONS, YOU HAVE SUCCESSFULLY REGISTERED! Now You can able to login");
                navigate("/login");
            })
            .catch((oError) => {
                console.log("error in resgister:: ", oError)
                showToast(oError.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
}
