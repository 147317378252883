import React, { useEffect, useState } from "react";
import { BiPlus, BiSave } from "react-icons/bi";
import Select from "react-select";
import useAppContext from "../../hooks/useAppContext";
import { useUserContext } from "../../hooks/UserContext";
import { serverAxios } from "../../utils/AxiosUtils";
import TablePro from "../newTable/TablePro";
import Popup from "../popupModel/Popup";
import { AddParamsAndCondition } from "./AddParamsAndCondition";
import './UtilityColumnComp.scss';
import { InputSection } from "../FieldSection.jsx/field";
export default function UtilityColumnComp(
  { utilitySetId, selectedTable, refreshHandle, refreshParam }
) {
  const { setLoading, } = useAppContext();
  const { showToast } = useUserContext();
  const [selectedAnonymizeFields, setSelectedAnonymizeFields] = useState(selectedTable.anonymizeFields || []);
  const utilityParams = useUtilityParam();
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [addParamsAndCondition, setAddParamsAndCondition] = useState(false);
  const [popupText, setPopupText] = useState('');
  const [popupResult, setPopupResult] = useState(false);
  const [idToBeDelete, setIdToBeDelete] = useState("");
  const handleOpenPopup = (text, id) => {
    setPopupText(text);
    setShowPopup(true);
    setIdToBeDelete(id);
  };
  const handleConfirm = (result) => {
    setPopupResult(result);

    if (result) {
      handleDeleteRowOfSelectedTable(idToBeDelete);
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  };
  const fields = () => {
    refreshParam(selectedTable.id)
    setSelectedAnonymizeFields(selectedTable.anonymizeFields || []);
  }
  useEffect(() => {

    fields()
  }, [selectedTable.anonymizeFields]);
  const onUtilityParamChange = (selectedOption, columnName) => {
    const newAnonymizeFields = selectedAnonymizeFields.map((item) => {
      if (item.name === columnName) {
        item.utilityParam = selectedOption;
        item.utilityParamConditions = [];
      }
      return item;
    });
    setSelectedAnonymizeFields(newAnonymizeFields);
    setShowSaveButton(true);
  }
  const onPrivacyRelevanceChange = (selectedData, columnName) => {
    const newAnonymizeFields = selectedAnonymizeFields.map((item) => {
      if (item.name === columnName) {
        item.privacyRelevance = selectedData.value;
      }
      return item;
    });
    setSelectedAnonymizeFields(newAnonymizeFields);
    setShowSaveButton(true);
  }
  const onUtilityParamConditionsChange = (selectedOptions, columnName) => {
    const newAnonymizeFields = selectedAnonymizeFields.map((item) => {
      if (item.name === columnName) {
        item.utilityParamConditions = selectedOptions;
      }
      return item;
    });
    setSelectedAnonymizeFields(newAnonymizeFields);
    setShowSaveButton(true);
  }

  const updateUtilityParamAndConditions = async () => {
    selectedAnonymizeFields.forEach(field => {
      if (!field.utilityParam || Object.keys(field.utilityParam)?.length === 0) {
        alert("Empty utilityParam is not allowed for field: " + field.name);
      }
    });
    setLoading(true);
    serverAxios.appSafeAxios.post(`utility-set/${utilitySetId}/tables/${selectedTable.id}/update-columns`, selectedAnonymizeFields)
      .then(res => {
        setShowSaveButton(false);
        showToast(res.data.message, "success");
        refreshParam(selectedTable);
      }).catch(err => {
        showToast(err.message);
      }).finally(() => {
        setLoading(false)
      });
  }

  const handleView = (item) => {

  }

  const handleDeleteRowOfSelectedTable = async (item) => {
    await serverAxios.appSafeAxios.post(`utility-set/${utilitySetId}/tables/${selectedTable.id}/columns/${item}/delete`).then((res) => {
      refreshHandle();
      const updatedFields = selectedAnonymizeFields.filter(field => field.name !== item);
      setSelectedAnonymizeFields(updatedFields);
      showToast(res.data.message, "success");
      refreshParam(selectedTable);
      fields()

    }).catch(err => {
      showToast(err.message, "fail");
    }).finally(() => {
      setLoading(false)
    });

  }
  const customStyles = {
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      '.mid-table-col-5': {
        zIndex: 9999
      }
    }),
  };
  const availablePrivacyRelevance = [
    { value: 'NOT_RELEVANT', label: 'Not Relevant' },
    { value: 'CONFIDENTIAL', label: 'Confidential' },
    { value: 'PERSONAL', label: 'Personal' },
  ];
  const onCustomExpChange = (value, columnName) => {
    const newAnonymizeFields = selectedAnonymizeFields.map((item) => {
      if (item.name === columnName) {
        item.customExpression = value;
      }
      return item;
    });
    setSelectedAnonymizeFields(newAnonymizeFields);
    setShowSaveButton(true);
  }
  const onCustomFixedValuesChange = (value, columnName) => {
    const newAnonymizeFields = selectedAnonymizeFields.map((item) => {
      if (item.name === columnName) {
        item.customParameter = value
      }
      return item;
    });
    setSelectedAnonymizeFields(newAnonymizeFields);
    setShowSaveButton(true);
  }
  const columnParameterTableHeaders = [
    {
      name: "Table",
      field: "tableName",
      type: "customControl",
      width: "11%",
      accessor: 'name',
      fnRenderCustomControl: (row) => {
        return (
          <>
            <p className='font-4'
            >{selectedTable.tableName}</p>
          </>
        )
      }
    },
    {
      name: "Column",
      field: "name",
      type: "text",
      width: "10%",
    },
    {
      name: "Utility Parameter",
      field: "utilityParameter",
      type: "customControl",
      width: "25%",
      fnRenderCustomControl: (row) => {
        return (
          <div className="mid-table-col-5 cursor-pointer" data-toggle="tooltip" data-placement="top"
            title="Search or select an option...">
            <div className='action-dropdown'>
              <div className="params-con-draopdown" key={row.name}
              >
                <Select className='font-4'
                  options={utilityParams}
                  value={row.utilityParam}
                  onChange={(selectedOption) => onUtilityParamChange(selectedOption, row.name)}
                  placeholder="Search or select an option..."
                  styles={customStyles}
                  menuPortalTarget={document.body}
                  menuPlacement="auto"

                />
              </div>

            </div>

          </div>
        )
      }
    },
    {
      name: "Utility Parameter Condition",
      field: "utilityParamConditions",
      type: "customControl",
      width: "30%",
      fnRenderCustomControl: (row) => {
        const utilityParam = row.utilityParam;
        const availableConditions = utilityParams
          .filter(u => {
            if (!utilityParam) return false;
            return u.label === utilityParam.label;
          })
          .flatMap(u => {
            if (u.utilityParamConditions) return u.utilityParamConditions;
            return []
          }
          );


        return (
          <>
            {
              row.utilityParam?.label === 'CUSTOM_EXPRESSION' ? <>
                <InputSection label={''} value={row.customExpression} onChange={(e) => onCustomExpChange(e.target.value, row.name)} placeholder="Type..." name={'customExpression'} id={''} />
              </> : row.utilityParam?.label === 'FIXED_VALUE' ? <>
                  <input className="input-field-shadow font-4" label={''} value={row.customParameter} onChange={(e) => onCustomFixedValuesChange(e.target.value, row.name)} placeholder="Type..." name={'customParameter'} id={''} />

              </> : <div className="mid-table-col-5" data-toggle="tooltip" data-placement="top"
                title="Search or select multiple options...">
                <div className='action-dropdown'>
                  {/* <div className="drop-1 one-1" key={row.columnName}> */}
                  <div className="params-con-draopdown">
                    <Select className='font-4'
                      options={availableConditions}
                      value={row.utilityParamConditions}
                      onChange={(selectedOption) => onUtilityParamConditionsChange(selectedOption, row.name)}
                      isMulti
                      placeholder={availableConditions?.length === 0 ? 'No Options' : row.utilityParamConditions?.length === 0 ? 'Use Default' : 'Select...'}
                      styles={customStyles}
                      menuPlacement="auto"
                      menuPortalTarget={document.body}
                    />
                  </div>
                </div>

              </div>}
          </>
        )
      }
    },
    {
      name: "Privacy Relevance",
      type: "customControl",
      width: "25%",
      fnRenderCustomControl: (row) => {
        const selectedOption = availablePrivacyRelevance.find(
          (option) => option.value === row.privacyRelevance
        );
        return (
          <div className="mid-table-col-5" data-toggle="tooltip" data-placement="top"
            title="Search or select multiple options...">
            <div className='action-dropdown'>
              {/* <div className="drop-1 one-1" key={row.columnName}> */}
              <div className="params-con-draopdown">
                <Select className='font-4'
                  options={availablePrivacyRelevance}
                  value={selectedOption}
                  onChange={(e) => onPrivacyRelevanceChange(e, row.name)}
                  placeholder="Select..."
                  styles={customStyles}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                />
              </div>
            </div>

          </div>
        )
      }
    },
    {
      name: "Action",
      field: "",
      type: "customControl",
      width: "20%",
      fnRenderCustomControl: (item) => {
        return (
          <div className="mid-table-col-5">
            <div className='action-btn-h'>
              <svg className='item-1 cursor-pointer' onClick={() => handleView(item)}
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                fill="none">
                <path
                  d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                  stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                  strokeLinejoin="round" />
                <path
                  d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                  stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                  strokeLinejoin="round" />
              </svg>
              <svg className='item-1 cursor-pointer'
                // onClick={() => handleDeleteRowOfSelectedTable(item)}
                onClick={() => handleOpenPopup('Do you wish to proceed with deleting this row?', item.name)}
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                fill="none">
                <g clipPath="url(#clip0_6470_50043)">
                  <path
                    d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                    stroke="#667085" strokeWidth="1.66667" strokeLinecap="round"
                    strokeLinejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_6470_50043">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>

          </div>
        )
      }
    }
  ]
  return <div className=" inner-table pt-t">
    {showPopup && (
      <div className='backdropPopupStyle'  >
        <Popup popupTitle={"Deletion"}
          popupMessage={popupText}
          isConfirm={handleConfirm} />
      </div>

    )}
    <div className="table-content-step-2">
      <div className="table-header">
        <div className="rowone w-100 ">
          <div className=" col21 s font-4">
            {selectedAnonymizeFields?.length} Fields
          </div>
          <div className="d-flex gap-3 justify-content-center align-items-center pb-2">
            <button type="button" className="res-back-btn font-6 "
              onClick={() => setAddParamsAndCondition(true)}>
              <BiPlus /> Add Column
            </button>
            {showSaveButton &&
              <button type=" button" className="btn-purple font-6" onClick={() => {
                updateUtilityParamAndConditions()
              }} data-toggle="tooltip" data-placement="top" title="Tooltip on top">
                <BiSave /> Save Param and Condition
              </button>}
          </div>
        </div>
      </div>
      <div className="table-content" id='targetSection'>
        <TablePro columns={columnParameterTableHeaders}
          data={selectedAnonymizeFields} minRows={5} refresh={true}
          refreshHandle={fields} />
      </div>
    </div>
    {addParamsAndCondition && <AddParamsAndCondition utilitySetId={utilitySetId} tableId={selectedTable} refresh={refreshHandle} refreshParam={fields} isPanel={() => setAddParamsAndCondition(false)} />}
  </div>

}

const useUtilityParam = () => {
  const [utilityParams, setParamOptions] = useState([]);
  useState(() => {
    serverAxios.appSafeAxios.get(`utility-set/utility-params`)
      .then(res => {
        const utilityParams = res.data.data;
        setParamOptions(utilityParams)
      }).catch(err => {
        console.error(err)
      })
  });
  return utilityParams;
}