import "./NewTable.scss";
import { useState } from "react";
import Pagination from "../pagination/Pagination";
import { TbRefresh } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TablePro = ({
  columns,
  data = null,
  minRows,
  refresh = false,
  refreshHandler = null,
  search = false,
  filterButton = false,
  onSearch = null,
  rowClassName = null, // Add rowClassName prop
  showRecords=false
}) => {
  const { t } = useTranslation("utility");

  let navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  if (!columns || columns.length === 0)
    return console.error("Table must have Headers");

  const handleSearch = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchValue(searchTerm);
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  return (
    <>
      <div className="tableWrapper">
        <div className="d-flex justify-content-between w-100 align-items-center">
          {showRecords&&<div className="field font-5">
            {data.length} {t("Records")}
          </div>}
          {refresh && refreshHandler && (
            <div className="tableButtons">
              <span
                className="icon"
                onClick={() => {
                  refreshHandler();
                }}
              >
                <TbRefresh />
              </span>
            </div>
          )}
          {(filterButton || search) && (
            <div className="table-action d-flex justify-content-between">
              <div className="float-left left">
                {filterButton && (
                  <button className="btn-filter">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
                        stroke="#344054"
                        strokeWidth="1.67"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Filters
                  </button>
                )}
              </div>
              <div className="float-right right">
                {search && (
                  <div className="search-bar">
                    <svg
                      className="search-icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M17.5 17.5L13.875 13.875M15.8333 9.16667C15.8333 12.8486 12.8486 15.8333 9.16667 15.8333C5.48477 15.8333 2.5 12.8486 2.5 9.16667C2.5 5.48477 5.48477 2.5 9.16667 2.5C12.8486 2.5 15.8333 5.48477 15.8333 9.16667Z"
                        stroke="#667085"
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <input
                      className="search-input font-4 no-outline"
                      style={{ background: "transparent" }}
                      type="text"
                      placeholder={t("Search")}
                      value={searchValue}
                      onChange={handleSearch}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="table-container">
          <table className="table-component-content responsive-table">
            <thead className="t-head">
              <tr className="t-tr">
                {columns.map((headerItem, index) => (
                  <th className="font-5" key={index}>
                    {headerItem.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="t-body">
              {data && minRows
                ? data
                  .slice(minRows * (currentPage - 1), minRows * currentPage)
                  .map((rowItem, index) => (
                    <TableRowItem
                      key={index}
                      item={rowItem}
                      column={columns}
                      rowClassName={rowClassName} // Pass rowClassName to TableRowItem
                    />
                  ))
                : data.map((rowItem, index) => (
                  <TableRowItem
                    key={index}
                    item={rowItem}
                    column={columns}
                    rowClassName={rowClassName} // Pass rowClassName to TableRowItem
                  />
                ))}
            </tbody>
          </table>
          {data && minRows && data.length > minRows && (
            <Pagination
              currentPage={currentPage}
              total={data.length}
              limit={minRows}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
        {!data && <p> No Data</p>}
      </div>
    </>
  );
};

const TableRowItem = ({ item, column, rowClassName }) => {
  let navigate = useNavigate();
  return (
    <tr className={`font-4 ${rowClassName ? rowClassName(item) : ""}`}>
      {column.map((columnItem, index) => {
        let customColumn;
        switch (columnItem.type) {
          case "customControl":
            customColumn = (
              <span className="font-5">
                {columnItem.fnRenderCustomControl(item)}
              </span>
            );
            break;
          case "text":
            let text = item[`${columnItem.field}`];
            if (text === '') {
              text = '...';
            }
            if (columnItem.textFormatter) {
              text = columnItem.textFormatter(text);
            }
            customColumn = <span className="font-4">{text}</span>;
            break;
          case "status":
            customColumn = (
              <ObjectStatus key={index} value={item[`${columnItem.field}`]} />
            );
            break;
          case "link":
            customColumn = (
              <span>
                {item[`${columnItem.field}`] &&
                  item[`${columnItem.field}`].length !== 0 && (
                    <a
                      href={item[`${columnItem.field}`]}
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Download File
                    </a>
                  )}
              </span>
            );
            break;
          case "button":
            let disabled = false;
            if (typeof columnItem.enabled === "boolean") {
              disabled = !columnItem.enabled;
            } else if (typeof columnItem.enabledFn === "function") {
              disabled = columnItem.enabledFn(item);
            } else {
              disabled =
                typeof item[`${columnItem.enabled}`] === "boolean"
                  ? !item[`${columnItem.enabled}`]
                  : false;
            }
            customColumn = (
              <span>
                {item[`${columnItem.field}`] &&
                  item[`${columnItem.field}`].length !== 0 && (
                    <button
                      className={`font-4 ${columnItem.buttonType}`}
                      onClick={() =>
                        columnItem.onClick(item[`${columnItem.field}`])
                      }
                      disabled={disabled}
                    >
                      {columnItem.text}
                    </button>
                  )}
              </span>
            );
            break;
          case "date":
            const oDate = new Date(item[`${columnItem.field}`]);
            let sFormattedDate;
            if (item[`${columnItem.field}`] === null) {
              sFormattedDate = "N/A"
            }
            else if (columnItem && columnItem.display) {
              switch (columnItem.display) {
                case "date":
                  sFormattedDate = oDate.toDateString();
                  break;
                case "time":
                  sFormattedDate = oDate.toLocaleTimeString();
                  break;
                case null:
                  sFormattedDate = "...";
                  break;
                default:
                  sFormattedDate = oDate.toUTCString();
                  break;
              }
            } else {
              sFormattedDate = oDate.toLocaleString();
            }
            customColumn = <span className="font-4">{sFormattedDate}</span>;
            break;
          default:
            customColumn = (
              <span
                className={`font-4 ${columnItem.class}`}
                style={{ color: columnItem.color }}
              >
                {item[`${columnItem.field}`]}
              </span>
            );
        }
        return (
          <td
            style={{
              width: columnItem.width,
              color: columnItem.color,
              fontWeight: columnItem.fontWeight,
              cursor: columnItem.cursor,
            }}
            key={index}
          >
            {customColumn}
          </td>
        );
      })}
    </tr>
  );
};

const ObjectStatus = ({ value }) => {
  let sStatusClass = "";
  switch (value) {
    case "Finished":
      sStatusClass = "Success";
      break;
    case "Active":
      sStatusClass = "Info";
      break;
    case "warning":
      sStatusClass = "Warning";
      break;
    case "cancelled":
      sStatusClass = "Cancelled";
      break;
    case "error":
      sStatusClass = "Error";
      break;
    default:
      sStatusClass = "";
      break;
  }
  return <span className={sStatusClass + " font-4"}>{value}</span>;
};

export default TablePro;
