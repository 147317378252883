import React, { useState } from "react";
import { FaHome, FaChevronDown } from "react-icons/fa";
import { CiPlay1 } from "react-icons/ci";
import { IoSettingsOutline } from "react-icons/io5";
import { LuHexagon } from "react-icons/lu";
import { Link, useLocation } from "react-router-dom";
// const { REACT_APP_NEW_UI_URL } = process.env;

const SidebarNav = ({
  isCollapsed,
  selectedItem,
  handleItemClick,
  toggleSidebar,
}) => {
  const [servicesOpen, setServicesOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [premiumOpen, setPremiumOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = (setDropdownOpen) => {
    setDropdownOpen((prev) => !prev);
  };

  const isSelected = (path) => location.pathname === path;

  return (
    <div className="nav_sidebar">
      <nav className="nav">
        <ul className="nav_ul">
          <li
            className={`nav-item ${isSelected("/landing") ? "selected" : ""}`}
            onClick={() => handleItemClick("dashboard")}
          >
            <Link to="/landing">
              <FaHome className="icon" />
              {!isCollapsed && <span className="font-4">Dashboard</span>}
            </Link>
          </li>
          {/* <li>
            <a href={`${REACT_APP_NEW_UI_URL}/test`}>
              <FaHome className="icon" />
              {!isCollapsed && <span className="font-4">Testing</span>}
            </a>
          </li> */}
          <li className={`nav-item ${servicesOpen ? "open" : ""}`}>
            <a
              href="#services"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown(setServicesOpen);
                // toggleSidebar();
              }}
              className="sidebar_service"
            >
              <CiPlay1 className="icon" onClick={toggleSidebar} />
              {!isCollapsed && (
                <div className="sidebar_drop">
                  <span className="font-4">Runs</span>
                  <FaChevronDown
                    className={`dropdown-icon ${servicesOpen ? "rotated" : ""}`}
                  />
                </div>
              )}
            </a>
            {servicesOpen && !isCollapsed && (
              <ul className="dropdown">
                <li
                  className={isSelected("/newrun") ? "selected" : ""}
                  onClick={() => handleItemClick("newrun")}
                >
                  <Link to="/newrun">New App Safe Run</Link>
                </li>
                <li
                  className={isSelected("/dashboard/fa") ? "selected" : ""}
                  onClick={() => handleItemClick("dashboard/fa")}
                >
                  <Link to="/dashboard/fa">New FileSafe Run</Link>
                </li>
                <li
                  className={isSelected("/ai-powered") ? "selected" : ""}
                  onClick={() => handleItemClick("ai-powered")}
                >
                  <Link to="/ai-powered">AI-Powered Run</Link>
                </li>
                <li
                  className={isSelected("/history") ? "selected" : ""}
                  onClick={() => handleItemClick("history")}
                >
                  <Link to="/history">History</Link>
                </li>
              </ul>
            )}
          </li>
          <li className={`nav-item ${settingsOpen ? "open" : ""}`}>
            <a
              href="#settings"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown(setSettingsOpen);
              }}
              className="sidebar_service"
            >
              <IoSettingsOutline className="icon" onClick={toggleSidebar} />
              {!isCollapsed && (
                <div className="sidebar_drop">
                  <span className="font-4">Definitions</span>
                  <FaChevronDown
                    className={`dropdown-icon ${settingsOpen ? "rotated" : ""}`}
                  />
                </div>
              )}
            </a>
            {settingsOpen && !isCollapsed && (
              <ul className="dropdown">
                <li
                  className={
                    isSelected("/utilitySetHomePage") ? "selected" : ""
                  }
                  onClick={() => handleItemClick("utilitySetHomePage")}
                >
                  <Link to="/utilitySetHomePage">Utility Sets</Link>
                </li>
                <li
                  className={
                    isSelected("/Configuration-Home") ? "selected" : ""
                  }
                  onClick={() => handleItemClick("Configuration-Home")}
                >
                  <Link to="/Configuration-Home">Configuration Sets</Link>
                </li>
                <li
                  className={isSelected("/Execution-Home") ? "selected" : ""}
                  onClick={() => handleItemClick("Execution-Home")}
                >
                  <Link to="/Execution-Home">Execution Sets</Link>
                </li>
                <li
                  className={
                    isSelected("/definitions/settings") ? "selected" : ""
                  }
                  onClick={() => handleItemClick("definitions/settings")}
                >
                  <Link to="/definitions/settings">Settings</Link>
                </li>
              </ul>
            )}
          </li>
          <li className={`nav-item ${premiumOpen ? "open" : ""}`}>
            <a
              href="#premium"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown(setPremiumOpen);
              }}
              className="sidebar_service"
            >
              <LuHexagon className="icon" onClick={toggleSidebar} />
              {!isCollapsed && (
                <div className="sidebar_drop">
                  <span className="font-4">Premium</span>
                  <FaChevronDown
                    className={`dropdown-icon ${premiumOpen ? "rotated" : ""}`}
                  />
                </div>
              )}
            </a>
            {premiumOpen && !isCollapsed && (
              <ul className="dropdown">
                <li
                  className={isSelected("/ai-safe") ? "selected" : ""}
                  onClick={() => handleItemClick("ai-safe")}
                >
                  <Link to="/ai-safe">Privacy Ai</Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default SidebarNav;
