import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import CodeBlock from "./CodeBlock";
import "./Conversation.scss";
import anonymous from "../../../images/anonymous.png";
import { FaCopy, FaCheck } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";

const ConversationEntry = ({ entry, id }) => {
  const [popupContent, setPopupContent] = useState(
    "This is the anonymised response"
  );
  const [isCopied, setIsCopied] = useState(false);

  console.log("This is entry data", entry);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(entry.chatResponse).then(() => {
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000); // Reset after 2 seconds
    });
  };

  // Check if the id starts with "thread_"
  const isThread = id && id.startsWith("thread_");

  return (
    <div className="head_conversation_ai_safe">
      <div className="conversation_ai_safe">
        <div className="original_prompt">
          <div>{entry.originalPrompt}</div>
        </div>

        {!isThread && (
          <div className="anony_ai_safe">
            <div className="img_head_ai_safe">
              <img
                src={anonymous}
                alt="img"
                className="img_conversation"
                onMouseEnter={() =>
                  setPopupContent("This is the anonymised response")
                }
              />
              <div className="popup">{popupContent}</div>
            </div>
            <div className="anonymised_prompt">{entry.anonymizedPrompt}</div>
          </div>
        )}

        <div className="mt-2">
          <strong>Chat Response:</strong>
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || "");
                return !inline && match ? (
                  <CodeBlock
                    language={match[1]}
                    value={String(children).replace(/\n$/, "")}
                  />
                ) : (
                  <code {...props} className={className}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {isThread ? entry.anonymizedPrompt : entry.chatResponse}
            {/* {entry.chatResponse} */}
          </ReactMarkdown>
          <div
            className={`copy-button_ai ${isCopied ? "copied" : ""}`}
            onClick={copyToClipboard}
          >
            {isCopied ? <FaCheck /> : <MdContentCopy />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversationEntry;
