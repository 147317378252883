import React, { useState, useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { FiSettings, FiX } from "react-icons/fi";
import "./AiSafe.scss";
import { history } from "../../hooks/apis/aiSafe";

const SidebarAiSafe = ({ id, onSettingsClick, isOpen, onClose }) => {
  const [historyData, setHistoryData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const data = await history();
        console.log("Received history data:", data.data);

        if (data.data) {
          setHistoryData(data.data.data);
        } else {
          setError("Unexpected data format");
        }
      } catch (error) {
        setError("Failed to fetch history");
        console.error(error);
      }
    };

    fetchHistory();
  }, [id]); // Add `id` to the dependency array to re-fetch on `id` change

  const handleNewChat = () => {
    window.location.assign("/ai-safe/");
  };

  console.log("historyData:", historyData);

  return (
    <div className={`sidebar_ai_safe ${isOpen ? "open" : ""}`}>
      <div className="side_button_top">
        <button className="button_ai_safe font-" onClick={handleNewChat}>
          <FaPlus />
          New Chat
        </button>
        <button className="close_button" onClick={onClose}>
          <FiX />
        </button>
      </div>
      <div className="ai_safe_history">
        {Object.keys(historyData).length > 0 ? (
          Object.entries(historyData).map(([id, message]) => (
            <div key={id} className="comment_ai_safe_1 font-4">
              <a className="comment_ai font-4" href={`/ai-safe/c/${id}`}>
                {message}
              </a>
            </div>
          ))
        ) : (
          <div className="no-history">No history available</div>
        )}
      </div>
      {error && <div className="error-message">{error}</div>}
      <div className="bottom_setting">
        <div className="separator"></div>
        <div className="button_settings font-5" onClick={onSettingsClick}>
          <FiSettings />
          Settings
        </div>
      </div>
    </div>
  );
};

export default SidebarAiSafe;
