import React, { useEffect, useState } from "react";
import {
  InputSection,
  RadioSection,
  SelectSection,
} from "../../components/FieldSection.jsx/field";
import { ConfigurationType } from "../../model/Constants";
import {
  availableAuthType,
  availableDatabaseType,
  initialDataBaseData,
} from "./ConfigData";
import { UserContext, useUserContext } from "../../hooks/UserContext";

const DataBase = ({
  id,
  handleChange,
  formDataForSource,
  error,
  setFormData,
}) => {
  const [sameConfig, setSameConfig] = useState(formDataForSource.sameConfig);
  const { showToast } = useUserContext();

  // const handleToggleChange = () => {
  //     setIsSameAsSource(!isSameAsSource);
  //     if (!isSameAsSource) {
  //         handleChange({ target: { name: 'targetConfig', value: formDataForSource } });
  //     } else {
  //         handleChange({ target: { name: 'targetConfig', value: {} } });
  //     }
  // };

  const databaseConfig = (configTitle) => {
    let configdatabaseMode = "";
    let databaseType = "";
    let authType = "";
    let hanaDbType = "";
    let databaseHost = "";
    let databasePort = "";
    let databaseUser = "";
    let databasePassword = "";
    let databaseName = "";
    let schemaName = "";
    let backupSchema = "";
    if (configTitle === "sourceConfig") {
      configdatabaseMode =
        formDataForSource?.sourceConfig?.config?.databaseMode;
      databaseType = formDataForSource?.sourceConfig?.databaseType;
      authType = formDataForSource?.sourceConfig?.config?.authType;
      hanaDbType = formDataForSource?.sourceConfig?.config?.hanaDbType;
      databaseHost = formDataForSource?.sourceConfig?.config?.databaseHost;
      databasePort = formDataForSource?.sourceConfig?.config?.databasePort;
      databaseUser = formDataForSource?.sourceConfig?.config?.databaseUser;
      databasePassword =
        formDataForSource?.sourceConfig?.config?.databasePassword;
      databaseName = formDataForSource?.sourceConfig?.config?.databaseName;
      schemaName = formDataForSource?.sourceConfig?.config?.schemaName;
      backupSchema = formDataForSource?.sourceConfig?.config?.backupSchema;
    } else if (configTitle === "targetConfig") {
      configdatabaseMode =
        formDataForSource?.targetConfig?.config?.databaseMode;
      databaseType = formDataForSource?.targetConfig?.databaseType;
      authType = formDataForSource?.targetConfig?.config?.authType;
      hanaDbType = formDataForSource?.targetConfig?.config?.hanaDbType;
      databaseHost = formDataForSource?.targetConfig?.config?.databaseHost;
      databasePort = formDataForSource?.targetConfig?.config?.databasePort;
      databaseUser = formDataForSource?.targetConfig?.config?.databaseUser;
      databasePassword =
        formDataForSource?.targetConfig?.config?.databasePassword;
      databaseName = formDataForSource?.targetConfig?.config?.databaseName;
      schemaName = formDataForSource?.targetConfig?.config?.schemaName;
      backupSchema = formDataForSource?.targetConfig?.config?.backupSchema;
    }

    return (
      <>
        <SelectSection
          label="DataBase Type"
          name="databaseType"
          onChange={(e) => handleChange(e, configTitle, "databaseType", null)}
          value={databaseType}
          options={availableDatabaseType}
          id={id}
          required={true}
          error={error}
          placeholder="Select type"
        />
        <InputSection
          label="Database Host"
          name="databaseHost"
          value={databaseHost}
          onChange={(e) =>
            handleChange(e, configTitle, "config", "databaseHost")
          }
          required={true}
          error={error}
        />
        <InputSection
          label="Database Port"
          name="databasePort"
          value={databasePort}
          onChange={(e) =>
            handleChange(e, configTitle, "config", "databasePort")
          }
          required={true}
          error={error}
        />
        <SelectSection
          label="Auth Type"
          name="authType"
          onChange={(e) => handleChange(e, configTitle, "config", "authType")}
          value={authType}
          options={availableAuthType}
          id={id}
          required={true}
          error={error}
        />
        {authType === "PASSWORD" && (
          <>
            <InputSection
              label="Database User"
              name="databaseUser"
              value={authType === "NONE" ? "" : databaseUser}
              onChange={(e) =>
                handleChange(e, configTitle, "config", "databaseUser")
              }
              required={true}
              error={error}
            />
            <InputSection
              label="Database Password"
              name="databasePassword"
              value={databasePassword}
              onChange={(e) =>
                handleChange(e, configTitle, "config", "databasePassword")
              }
              required={true}
              error={error}
              type="password"
            />
          </>
        )}
        {databaseType === "HANA" && (
          <>
            <RadioSection
              title="Database Mode"
              name="databaseMode"
              value1="SINGLE_CONTAINER"
              value2="MULTIPLE_CONTAINER"
              checked1={configdatabaseMode === "SINGLE_CONTAINER"}
              onChange={(e) =>
                handleChange(e, configTitle, "config", "databaseMode")
              }
              label1="SINGLE_CONTAINER"
              checked2={configdatabaseMode === "MULTIPLE_CONTAINER"}
              label2="MULTIPLE_CONTAINER"
              uniqueId={"databaseMode" + configTitle}
            />
            {configdatabaseMode != "SINGLE_CONTAINER" && (
              <RadioSection
                title="HanaDb Type"
                name="hanaDbType"
                value1="SYSTEM"
                value2="TENANT"
                checked1={hanaDbType === "SYSTEM"}
                onChange={(e) =>
                  handleChange(e, configTitle, "config", "hanaDbType")
                }
                label1="SYSTEM"
                checked2={hanaDbType === "TENANT"}
                label2="TENANT"
                uniqueId={"hanaDbType" + configTitle}
              />
            )}
          </>
        )}
        {(databaseType === "ORACLE" ||
          databaseType === "POSTGRES" ||
          (databaseType === "HANA" &&
            configdatabaseMode != "SINGLE_CONTAINER")) && (
          <InputSection
            label={
              configdatabaseMode === "MULTIPLE_CONTAINER" &&
              hanaDbType === "TENANT"
                ? "Tenant Name"
                : "Database Name"
            }
            name="databaseName"
            value={hanaDbType === "SYSTEM" ? "SYSTEM" : databaseName}
            onChange={(e) =>
              handleChange(e, configTitle, "config", "databaseName")
            }
            disabled={hanaDbType === "SYSTEM" && true}
            placeholder={databaseType === "ORACLE" && "Default:XE"}
          />
        )}
        <InputSection
          label="Schema Name"
          name="schemaName"
          value={schemaName}
          onChange={(e) => handleChange(e, configTitle, "config", "schemaName")}
          required={databaseUser === "" ? true : false}
          error={databaseUser === "" && error}
          placeholder={
            databaseType === "POSTGRES"
              ? "Default:public"
              : "default:Database User"
          }
        />
        <InputSection
          label="Backup Schema"
          name="backupSchema"
          value={backupSchema}
          onChange={(e) =>
            handleChange(e, configTitle, "config", "backupSchema")
          }
          placeholder="Default: Schema Name"
        />
      </>
    );
  };
  return (
    <>
      <div className="d-flex w-100   gap-4 ">
        <div className="d-flex w-50 flex-column gap-3">
          <p className="mr-auto text-level font-5 mb-0">Source</p>
          {databaseConfig("sourceConfig")}
        </div>
        <div className="d-flex w-50 flex-column gap-2">
          <div className="d-flex align-items-center gap-5 ">
            <p className="mr-auto text-level font-5 mb-0">Target</p>
            <span className="font-4 d-flex gap-1">
              {" "}
              <input
                type="checkbox"
                onClick={() => setSameConfig(!sameConfig)}
                onChange={async (e) => {
                  await handleChange(e, null, "sameConfig", null);
                  //  showToast("This functionality is under Beta release, please contact support@mayadataprivacy.eu for any issues", "fail");
                }}
                className=" ml-4"
                value={formDataForSource.sameConfig}
                checked={formDataForSource.sameConfig}
              />
              Target Same as Source
            </span>
          </div>
          {formDataForSource.sameConfig === false && (
            <div
              className={`pt-3 d-flex  flex-column gap-3 ${
                formDataForSource.sameConfig === true ? "disabled" : ""
              }`}
            >
              {databaseConfig("targetConfig")}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DataBase;
