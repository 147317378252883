import React, { useContext, useEffect, useState } from "react";
import { UserContext, useUserContext } from "../../hooks/UserContext";
import TablePro from "../../components/newTable/TablePro";
import "./UtilitySetHomePage.scss";

import useAppContext from "../../hooks/useAppContext";
import StatisticCard from "../../components/StatisticCard/StatisticCard";
import GraphComponent from "../../components/GraphComponent/GraphComponent";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import { AppSafeUrl } from "../../model/AppSafeUrl";
import { serverAxios } from "../../utils/AxiosUtils";
import { BsUpload } from "react-icons/bs";
import { uploadFileInUtilitySet } from "../../hooks/apis/useAppSafeApi";
import { useTranslation } from "react-i18next";

const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;
const UtilitySetHomePage = () => {
  const { t } = useTranslation("utility");

  const { showToast } = useUserContext();
  const { isLoading, setLoading } = useContext(UserContext);
  const { popupModel } = useAppContext();
  const [filteredData, setFilteredData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [fullTableData, setFullTableData] = useState([]);
  const navigate = useNavigate();

  const graphData = [
    { date: "2023-09-01", value: 100 },
    { date: "2023-09-02", value: 110 },
    { date: "2023-09-03", value: 120 },
  ];
  const handleDeleteUtilitygRow = (data) => {
    const process = data.archivable ? "archive" : "delete";
    setLoading(true)
    serverAxios.appSafeAxios
      .post(`utility-set/${data.utilitySetId}/${process}`, {
        "content-type": "application/json",
      })
      .then((res) => {
        const receivedData = res.data;
        showToast(receivedData.message, "success");
        getUtilitySetData();
      })
      .catch((err) => {
        console.error("config Delete error ->", err);
        showToast(err.message);
      })
      .finally(() => {
        setLoading(false)
      });
  };
  const columns = [
    {
      name: t("Utility Set Name"),
      field: "utilitySetName",
      type: "customControl",
      cursor: "pointer",
      fnRenderCustomControl: (item) => {
        return (
          <div className="font-5">
            <div
              className="font-5"
              onClick={() =>
                navigate(`/utility-set-table/${item.utilitySetId}`)
              }
            >
              {item.utilitySetName}
            </div>
          </div>
        );
      },
    },
    {
      name: t("Description"),
      field: "description",
      type: "text",
      color: "rgba(16, 24, 40, 1)",
      fontWeight: "400",
    },
    {
      name: t("Tables"),
      field: "noOfTables",
      type: "text",
      color: "rgba(16, 24, 40, 1)",
      // fnRenderCustomControl: (item) => {
      //     return item.tables ? item.tables.length : 0
      // }
    },
    {
      name: t("Fields"),
      field: "noOfFields",
      type: "text",
      color: "rgba(16, 24, 40, 1)",
      // fnRenderCustomControl: (item) => {
      //     if (!item.tables) return 0;
      //     const tables = item.tables;
      //     return Object.values(tables)
      //         .flatMap(table => table.anonymizeFields || [])
      //         .length;
      // }
    },
    {
      name: t("LastEdit"),
      field: "updatedAt",
      type: "date",
      display: "date",
      color: "rgba(16, 24, 40, 1)",
    },

    {
      name: t("Actions"),
      type: "customControl",
      width: "18%",
      fnRenderCustomControl: (item) => {
        // Define your custom action buttons here
        const handleCopy = () => {
          const copiedText = `UtilitySetName-${item.utilitySetName}, Description ${item.description}`;

          // Copy the text to the clipboard
          navigator.clipboard
            .writeText(copiedText)
            .then(() => {
              showToast(`Copied text: ${copiedText}`, "success");
            })
            .catch((error) => {
              showToast("Error copying text:", error);
            });
        };
        const handlePlay = () => {
          alert(`Editing: ${item.name} `);
        };
        const handleEdit = (a) => {
          console.log("onclicking:: ", a);
        };

        return (
          <div className="action-btn-h">
            <svg
              className="cursor-pointer item-1"
              onClick={() => handleCopy(item)}
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_7119_14580)">
                <path
                  d="M4.16699 12.5013H3.33366C2.89163 12.5013 2.46771 12.3257 2.15515 12.0131C1.84259 11.7006 1.66699 11.2767 1.66699 10.8346V3.33464C1.66699 2.89261 1.84259 2.46868 2.15515 2.15612C2.46771 1.84356 2.89163 1.66797 3.33366 1.66797H10.8337C11.2757 1.66797 11.6996 1.84356 12.0122 2.15612C12.3247 2.46868 12.5003 2.89261 12.5003 3.33464V4.16797M9.16699 7.5013H16.667C17.5875 7.5013 18.3337 8.24749 18.3337 9.16797V16.668C18.3337 17.5884 17.5875 18.3346 16.667 18.3346H9.16699C8.24652 18.3346 7.50033 17.5884 7.50033 16.668V9.16797C7.50033 8.24749 8.24652 7.5013 9.16699 7.5013Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7119_14580">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              onClick={() =>
                navigate(`/utility-set-table/${item.utilitySetId}`)
              }
              className="cursor-pointer item-1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M4.16699 2.5L15.8337 10L4.16699 17.5V2.5Z"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              onClick={() => navigate(`/utility/${item.utilitySetId}`)}
              className="cursor-pointer item-1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <g clipPath="url(#clip0_7119_14588)">
                <path
                  d="M14.167 2.49895C14.3859 2.28008 14.6457 2.10646 14.9317 1.98801C15.2176 1.86956 15.5241 1.80859 15.8337 1.80859C16.1432 1.80859 16.4497 1.86956 16.7357 1.98801C17.0216 2.10646 17.2815 2.28008 17.5003 2.49895C17.7192 2.71782 17.8928 2.97766 18.0113 3.26362C18.1297 3.54959 18.1907 3.85609 18.1907 4.16562C18.1907 4.47514 18.1297 4.78164 18.0113 5.06761C17.8928 5.35358 17.7192 5.61341 17.5003 5.83228L6.25033 17.0823L1.66699 18.3323L2.91699 13.7489L14.167 2.49895Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_7119_14588">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <svg
              onClick={async () =>
                await popupModel(
                  "Removal Confirmation",
                  item.archivable
                    ? "The utility set is actively being utilized for job execution, thus removal is presently unavailable. However, you do have the option to archive it."
                    : "Do you wish to proceed with deleting this row?",
                  item.archivable ? "Archive" : "Delete",
                  () => handleDeleteUtilitygRow(item)
                )
              }
              className="cursor-pointer item-1"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M2.5 5.0013H4.16667M4.16667 5.0013H17.5M4.16667 5.0013V16.668C4.16667 17.11 4.34226 17.5339 4.65482 17.8465C4.96738 18.159 5.39131 18.3346 5.83333 18.3346H14.1667C14.6087 18.3346 15.0326 18.159 15.3452 17.8465C15.6577 17.5339 15.8333 17.11 15.8333 16.668V5.0013H4.16667ZM6.66667 5.0013V3.33464C6.66667 2.89261 6.84226 2.46868 7.15482 2.15612C7.46738 1.84356 7.89131 1.66797 8.33333 1.66797H11.6667C12.1087 1.66797 12.5326 1.84356 12.8452 2.15612C13.1577 2.46868 13.3333 2.89261 13.3333 3.33464V5.0013"
                stroke="#667085"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        );
      },
    },
  ];
  const xData = [0, 1, 2, 3, 4, 5];
  const yData = [0, 1, 4, 9, 16, 25];
  const cardDataOne = {
    heading: t("Utility Sets"),
    number: fullTableData.length,
    percentage: 25,
    discriptiontext: t("vs last month"),
    // graph: (
    //   <GraphComponent
    //     xAxisVisible={false}
    //     yAxisVisible={false}
    //     curveColor="rgba(18, 183, 106, 1)"
    //     shadowColor="rgba(18, 183, 106, 1)"
    //     xData={xData}
    //     yData={yData}
    //   />
    // ),
  };
  const cardDataTwo = {
    heading: t("Tables"),
    number: fullTableData.reduce((acc, item) => acc + item.noOfTables, 0),
    percentage: 10,
    discriptiontext: t("vs last month"),
  };
  const cardDataThree = {
    heading: t("Fields"),
    number: fullTableData.reduce((acc, item) => acc + item.noOfFields, 0),
    percentage: 20,
    discriptiontext: t("vs last month"),
  };
  const handelClickAdd = () => {
    // Navigation("./utility");
    navigate("/utility");
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      showToast("No file selected or unsupported file type.", "fail");
      return;
    } else {
      const formData = new FormData();
      formData.append("file", file);
      await uploadFileInUtilitySet(formData)
        .then((res) => {
          showToast(
            res.message ? res.message : "Successfully uploaded",
            "success"
          );
          getUtilitySetData();
        })
        .catch((err) => {
          showToast(
            err.message
              ? err.message
              : "Something went wrong! Please try again",
            "fail"
          );
        });
    }
  };
  useEffect(() => {
    getUtilitySetData();
    // getConditionTableData();
  }, []);

  const getUtilitySetData = async () => {
    try {
      const response = await serverAxios.appSafeAxios.get("utility-set");
      // console.log('Utility set data:', response.success);
      const responseData = response.data;
      if (responseData.success) {
        setFullTableData(responseData.data);
        setFilteredData(responseData.data);
      } else {
        showToast(responseData.message);
        console.error("Error details:", response);
      }
    } catch (err) {
      showToast(err.message, "fail");
      setErrorMessage(err.message);
      console.error("Error details:", err);
    }
  };

  const onSearch = (keyWord) => {
    if (!keyWord || keyWord === "") {
      setFilteredData(fullTableData);
      return;
    }
    setFilteredData(
      fullTableData.filter((rowItem) =>
        rowItem[columns[0].field].toLowerCase().includes(keyWord)
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Utility Home - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      <div className="main-section">
        <div className="header-section-maya h-section">
          <div className="header-text  ">
            <h1 className="heading1 font-5">{t("Utility Sets")}</h1>
            <h4 className="sub-heading font-4">{t("subheading")}</h4>
          </div>
          <div className="d-flex gap-4 header-btn">
            <div>
              <input
                type="file"
                id="fileUpload"
                style={{ display: "none" }}
                accept=".xlsx, .xls, .xlsm, .xlsb, .csv"
                onChange={handleFileUpload}
              />
              <label
                htmlFor="fileUpload"
                className="btn-low-purple"
                style={{
                  cursor: "pointer",
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                <BsUpload size={18} className="font-6" />
                {t("btn1")}
              </label>
            </div>
            <button
              className="btn-purple"
              type="navigation"
              onClick={handelClickAdd}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10.0003 4.16797V15.8346M4.16699 10.0013H15.8337"
                  stroke="white"
                  strokeWidth="1.67"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {t("btn2")}
            </button>
          </div>
        </div>
        <div className="body-section-maya contain-util">
          <div className="container-fluid">
            <div className="row g-2">
              <div className="col">
                <StatisticCard {...cardDataOne} />
              </div>
              <div className="col">
                <StatisticCard {...cardDataTwo} />
              </div>
              <div className="col">
                <StatisticCard {...cardDataThree} />
              </div>
            </div>
          </div>
          <div className="table-con">
            {errorMessage && errorMessage}{" "}
            <TablePro
              columns={columns}
              data={filteredData.sort(
                (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
              )}
              minRows={5}
              search={true}
              onSearch={onSearch}
              refreshHandler={getUtilitySetData}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default UtilitySetHomePage;
