import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

const Job_Name_File_Safe = ({ show, setShow, handleSubmitJob, jobName, setJobName, keypress }) => {
    
  return (
    <>
          <Modal
              show={show}
              centered
              onHide={() => setShow(false)}
          >
              <Modal.Header>
                  <Modal.Title>Enter Job Name</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form>
                      <Form.Group className="mb-3" controlId="jobName">
                          <Form.Label>Job Name</Form.Label>
                          <Form.Control
                              type="jobName"
                              autoFocus
                              value={jobName}
                              onChange={(e) => setJobName(e.target.value)}
                              onKeyDown={keypress}
                          />
                      </Form.Group>
                  </Form>
              </Modal.Body>

              <Modal.Footer>
                  <Button
                      variant="secondary"
                      className="closeButton"
                      onClick={() => setShow(false)}
                  >
                      Close
                  </Button>
                  <Button
                      variant="primary"
                      className="jobSubmit"
                      onClick={handleSubmitJob}
                  >
                      Submit
                  </Button>
              </Modal.Footer>
          </Modal></>
  )
}

export default Job_Name_File_Safe
