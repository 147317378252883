const INFO_KEY = "info";

const saveInfo = (user) => {
    try {
        localStorage.setItem(INFO_KEY, JSON.stringify(user));
    } catch (err) {
        console.error("error on saveInfo in localstorgateutil component: ", err)

    }
}


const loadInfo = () => {
    try {
        const loginStr = localStorage.getItem(INFO_KEY)
        if (!loginStr) return null;
        return JSON.parse(loginStr);
    } catch (err) {
        console.error("error on localstorage component: ", err)
        return null;
    }
}

export const StoragePref = {
    user: {
        save: saveInfo,
        load: loadInfo
    },
    clear: () => {
        localStorage.clear()
        sessionStorage.clear()
    }
}