import React from 'react';
import { useTranslation } from 'react-i18next';
import { RxCross1 } from 'react-icons/rx';
import { FiZap } from 'react-icons/fi';
import { BiSave } from 'react-icons/bi';

export const InputSection = ({
    label,
    name,
    value,
    onChange,
    required,
    type = "text",
    placeholder = "",
    disabled = false,
    error,
    id
}) => {
    return (
        <div className={`input-section ${disabled && 'disabled'}`}>
            {label !== '' && <label className="level-text font-5">{label} {required && "*"}</label>}
            <input
                type={type}
                className="input-field-shadow font-4"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                required={required}
                placeholder={placeholder}
                disabled={disabled}
            />
            {error && required && value === "" && <span className="text-danger font-4" style={{ fontSize: '12px' }}>{label} is Required </span>}

        </div>
    );
};


export const SelectSection = ({ label, name, value, onChange, options, uniqueId, required, placeholder, error }) => {
    return (
        <div className={`input-section ${uniqueId && 'disabled'}`}>
            <label className="level-text font-5">{label}  {required && "*"}</label>
            <select
                className="dropDown-field-maya select font-4"
                onChange={onChange}
                name={name}
                value={value}
                disabled={uniqueId ? true : false}
                required={required}
                placeholder={placeholder && placeholder}
            >
                <option name='' key={''} value="" className="drop-select">
                    {/* {t('option')} */}
                    Select...
                </option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} className={`font-4  ${option.disabled ? 'bg-dark bg-gradient  bg-opacity-10' : 'drop-select'}`}
                        disabled={option.disabled}>
                        {option.label}
                    </option>
                ))}
            </select>
            {error && required && value === "" && <span className="text-danger font-4" style={{ fontSize: '12px' }}>{label} is Required </span>}

        </div>
    );
};
export const FooterActions = ({
    isCancel,
    isInitialSave,
    isSave,
    id,
    cancelWithId,
    onCancle,
    handleSaveData,
    handleOpenPopup,
    error
}) => {
    const { t } = useTranslation('config');

    return (
        <div className="util-footer">
            {isCancel && <div className="col1">
                <button
                    type="button"
                    onClick={async () => {
                        error(false)
                        if (id) {
                            cancelWithId();
                        } else {
                            await onCancle();
                        }
                    }}
                    className="res-back-btn font-5"
                >
                    <RxCross1 /> {t('Cancel')}
                </button>
            </div>}
            <div className="col3">
                {isInitialSave && <div className="test">
                    <button
                        type="button"
                        className="btn-low-purple font-5"
                        onClick={(e) => handleSaveData(e, true)}
                    >
                        <FiZap color="#6941C6" /> {t('test')}
                    </button>
                </div>}
                {isSave && <div className="save">
                    <button
                        type="submit"
                        className="btn-purple font-5"
                        onClick={(e) =>
                            id
                                ? handleOpenPopup('Update', 'Do you wish to proceed with updating the data?')
                                : handleSaveData(e)
                        }
                    >
                        <BiSave color="#FFFFF" /> {id ? t('update') : t('save')}
                    </button>
                </div>}
            </div>
        </div>)
}
export const RadioSection = ({ title, name, value1, value2, checked1, onChange, label1, checked2, label2, uniqueId, isDefaultFirst = false }) => {

    return (
        <div className="radio-field">
            <div className="title font-5">{title}</div>
            <div className="form-check pt-2">
                <input
                    type="radio"
                    className="form-check-input"
                    id={uniqueId}
                    name={uniqueId}
                    value={value1}
                    checked={checked1 || isDefaultFirst}
                    onChange={onChange}
                />
                <label className="level-text form-check-label font-4"> {label1} </label>
            </div>
            <div className="form-check pt-2">
                <input
                    type="radio"
                    className="form-check-input"
                    id={uniqueId}
                    name={uniqueId}
                    value={value2}
                    checked={checked2}
                    onChange={onChange}
                />
                <label className="level-text form-check-label font-4"> {label2} </label>
            </div>
        </div>
    )
}
