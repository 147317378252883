import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchChatHistory, chat } from "../../hooks/apis/aiSafe";
import SidebarAiSafe from "./SidebarAiSafe";
import "./Chat.scss";

import { LuSendHorizonal } from "react-icons/lu";

import img1 from "../../images/setting.svg";
import ConversationEntry from "./main/ConversationEntry";
import ChatHistory from "./main/ChatHistory";
import PrivacySettings from "./main/PrivacySettings";
import ThemePopup from "./ThemePopup";
import { FiMenu } from "react-icons/fi";
import { GoPaperclip } from "react-icons/go";

const Chat = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [error, setError] = useState(null);

  const [privacySettings, setPrivacySettings] = useState({
    Names: true,
    Emails: true,
    Phone: false,
  });
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversationHistory, setConversationHistory] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [hasLanded, setHasLanded] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showFileUploadPopup, setShowFileUploadPopup] = useState(false);

  const conversationHistoryRef = useRef(null);
  const textareaRef = useRef(null);
  const sidebarRef = useRef(null);

  const handleCheckboxChange = (key) => {
    setPrivacySettings((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
  };

  useEffect(() => {
    const getChatHistory = async () => {
      try {
        const historyData = await fetchChatHistory(id);
        setHistory(historyData);
      } catch (error) {
        setError(error.message);
      }
    };

    if (id) {
      getChatHistory();
    }
  }, [id]);

  const handleChat = async () => {
    const trimmedPrompt = prompt.trim();
    if (!trimmedPrompt) return;

    const tempEntry = {
      originalPrompt: trimmedPrompt,
      anonymizedPrompt: "Loading...",
      chatResponse: "Loading...",
    };

    setConversationHistory((prevHistory) => [...prevHistory, tempEntry]);
    setLoading(true);
    setError("");
    setHasLanded(false);
    setPrompt("");

    try {
      const choices = Object.keys(privacySettings).filter(
        (key) => privacySettings[key]
      );

      const new_chat = id;
      const new_chat2 = "False";

      const chatResponse = await chat(
        trimmedPrompt,
        choices,
        new_chat,
        new_chat2
      );
      const { data, message, response_code, success } = chatResponse;

      if (response_code !== 200 || !success) {
        throw new Error(message);
      }

      const newEntry = {
        originalPrompt: prompt, // User's message
        anonymizedPrompt: data[1], // Anonymized message
        chatResponse: data[3], // Assistant's response
      };

      setConversationHistory((prevHistory) =>
        prevHistory.slice(0, -1).concat(newEntry)
      );

      const newId = data[0];
      navigate(`/ai-safe/c/${newId}`);

      setLoading(false);
    } catch (error) {
      setError(
        "An error occurred: " +
          (error.response?.status === 500
            ? "Internal Server Error"
            : error.message)
      );
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleChat();
    } else if (e.key === "Enter" && e.shiftKey) {
      setPrompt((prev) => prev + "\n");
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [prompt]);

  const showSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const closeSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !event.target.closest(".button_ai_safe_main2")
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  useEffect(() => {
    localStorage.setItem("theme", theme); // Store the theme in local storage
  }, [theme]);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const changeTheme = (newTheme) => {
    setTheme(newTheme);
    setIsPopupOpen(false);
  };

  useEffect(() => {
    document.body.className = theme; // Set the theme on the body element
  }, [theme]);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);
      // You can now handle file processing, sending to the server, etc.
    }
  };

  return (
    <>
      <div
        className={`ai_safe_sidebar ${sidebarVisible ? "visible" : ""}`}
        ref={sidebarRef}
      >
        <div className="ai_safe_sidebar-content">
          <PrivacySettings
            privacySettings={privacySettings}
            handleCheckboxChange={handleCheckboxChange}
            closeSidebar={closeSidebar}
          />
        </div>
      </div>

      <div className="head_chat">
        <div className="hamburger_icon" onClick={toggleSidebar}>
          <FiMenu />
        </div>
        <SidebarAiSafe
          isOpen={isSidebarOpen}
          onSettingsClick={togglePopup}
          onClose={toggleSidebar}
        />
        <div className="main_chat">
          {error && <div>Error: {error}</div>}
          <div className="chat_chat">
            <div>
              {history.length > 0 ? (
                history.map((entry, index) => (
                  <div key={index} className={`chat-entry ${entry.role}`}>
                    <ChatHistory key={index} children={entry} />
                  </div>
                ))
              ) : (
                <p>No chat history available.</p>
              )}
            </div>
            <div className="conversation_chat" ref={conversationHistoryRef}>
              {conversationHistory.map((entry, index) => (
                <ConversationEntry key={index} entry={entry} />
              ))}
            </div>
          </div>
          {/* </div> */}
          <div className="bottom_top_ai_safe">
            <div className="bottom_ai_safe_main">
              <div className="textarea_container">
                <div
                  onClick={() => setShowFileUploadPopup(true)}
                  className="upload_button "
                >
                  <GoPaperclip size={25} />
                </div>
                <textarea
                  ref={textareaRef}
                  className="input_ai_safe"
                  placeholder="Enter your prompt here..."
                  value={prompt}
                  onChange={(e) => {
                    setPrompt(e.target.value);
                    adjustTextareaHeight();
                  }}
                  onKeyPress={handleKeyPress}
                  rows={1}
                  style={{ overflowY: "hidden" }}
                ></textarea>

                {showFileUploadPopup && (
                  <div className="file-upload-popup">
                    <div className="popup-content">
                      The file upload feature is not supported.
                      <button onClick={() => setShowFileUploadPopup(false)}>
                        Close
                      </button>
                    </div>
                  </div>
                )}

                <div>
                  <div
                    className="left_20 button_ai_safe_main2"
                    onClick={showSidebar}
                  >
                    <img
                      src={img1}
                      alt=""
                      height={35}
                      width={35}
                      className="img_ai_safe"
                    />
                  </div>
                  <button
                    className="button_ai_safe_main"
                    onClick={handleChat}
                    disabled={loading}
                  >
                    <div className="logo_ai_safe_main">
                      <LuSendHorizonal />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ThemePopup
        isOpen={isPopupOpen}
        onClose={togglePopup}
        onThemeChange={changeTheme}
        currentTheme={theme}
      />
    </>
  );
};

export default Chat;
