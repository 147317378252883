// 1. File Drop
//   piiState -> START
// 2. Call PII API
// piiState ->  PII
// 3. Response from PII API
//   column set make
//  piiState -> COLUMN_SELECTED
// 4. Anonymize API CALL
// piiState -> ANONYMIZE
// 5. Response from anonymize
// creating data set for download
// piiState -> DATA_SET_CREATE
// piiState -> FINISH
import React, { useState, useRef, useEffect } from "react";
import "./AiPowered.scss";
import * as XLSX from "xlsx";
import mayaLogoLoader from "../../Maya-Logo-Loader (1).gif";
import Steps from "./Steps.jsx";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import { BiInfoCircle } from "react-icons/bi";
import useAppContext from "../../hooks/useAppContext";
import { serverAxios } from "../../utils/AxiosUtils.js";
import * as CONSTANTS from "../../model/Constants";
import { useTranslation } from "react-i18next";
const { REACT_APP_SITE_TITLE, REACT_APP_PII_URL, REACT_APP_BASE_URL } =
  process.env;

const AiPowredRun = () => {
  const { t } = useTranslation("airun");

  const FULL_URL = `${REACT_APP_BASE_URL}pii/generate-utility`;

  const navigate = useNavigate();
  const { showToast } = useAppContext();
  const [statusErrorMessage, setStatusErrorMessage] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const inputRef = useRef();
  const [fileHeaders, setFileHeaders] = useState([]);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [fileMetadata, setFileMetadata] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [seedToBeDownload, setSeedToBeDownload] = useState("");

  const demoFileUrl =
    "https://mayadataprivacy.eu/wp-content/uploads/2022/09/Maya-FileSafe-Demo-Sample.csv";
  const [piiState, setPiiState] = useState({
    INITIAL: true,
    START: false,
    PII: false,
    COLUMN_SELECT: false,
    ANONYMIZE: false,
    DATA_SET_CREATE: false,
    FINISH: false,
  });
  let uploadLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
    >
      <g clipPath="url(#clip0_7870_28124)">
        <path
          d="M28.4996 28.4996L21.4996 21.4996M21.4996 21.4996L14.4996 28.4996M21.4996 21.4996V37.2496M36.1821 32.6821C37.8889 31.7516 39.2373 30.2791 40.0143 28.4972C40.7914 26.7152 40.9529 24.7253 40.4734 22.8413C39.9939 20.9574 38.9007 19.2868 37.3662 18.0931C35.8318 16.8995 33.9436 16.2509 31.9996 16.2496H29.7946C29.2649 14.2008 28.2776 12.2987 26.907 10.6863C25.5363 9.074 23.818 7.79334 21.8812 6.94067C19.9444 6.08799 17.8395 5.68548 15.7248 5.7634C13.61 5.84132 11.5404 6.39763 9.67162 7.39052C7.80282 8.38341 6.18341 9.78704 4.93515 11.4959C3.6869 13.2047 2.84227 15.1743 2.46478 17.2565C2.08729 19.3388 2.18675 21.4795 2.75569 23.5178C3.32463 25.5561 4.34824 27.4389 5.74957 29.0246"
          stroke="#6941C6"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7870_28124">
          <rect
            width="42"
            height="42"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
  let downloadLogo = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_7934_29886)">
        <path
          d="M8.00018 16.9999L12.0002 20.9999M12.0002 20.9999L16.0002 16.9999M12.0002 20.9999V11.9999M20.8802 18.0899C21.7496 17.4786 22.4015 16.6061 22.7415 15.5991C23.0814 14.5921 23.0916 13.503 22.7706 12.4898C22.4496 11.4766 21.814 10.592 20.9562 9.96449C20.0985 9.33697 19.063 8.9991 18.0002 8.99993H16.7402C16.4394 7.82781 15.8767 6.73918 15.0943 5.81601C14.3119 4.89285 13.3303 4.15919 12.2234 3.67029C11.1164 3.18138 9.91302 2.94996 8.7037 2.99345C7.49439 3.03694 6.31069 3.3542 5.24173 3.92136C4.17277 4.48851 3.2464 5.29078 2.53236 6.26776C1.81833 7.24474 1.33523 8.37098 1.11944 9.56168C0.903647 10.7524 0.960787 11.9765 1.28656 13.142C1.61233 14.3074 2.19824 15.3837 3.00018 16.2899"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7934_29886">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
  const validFileType = (file) => {
    return CONSTANTS.SUPPORTED_FILE_TYPES.includes(file.type);
  };
  const getFileGroups = () => {
    serverAxios.fileSafeAxios
      .get("file/groups")
      .then((res) => {
        if (res.data.data.length < 1) {
          createGroup("Default", false);
          createGroup("Group - 1", false);
          createGroup("Group - 2", true);
          return;
        }
        let aSortedGroups = res.data.data.sort((a, b) =>
          a.name > b.name ? 1 : b.name > a.name ? -1 : 0
        );
        setSelectedGroup(aSortedGroups[0].seed);
      })
      .catch((oError) => {
        console.error(`Reading Groups Error ::::: ${oError}`);
      })
      .finally(() => {});
  };
  const createGroup = (groupName, bReadGroups = false) => {
    serverAxios.fileSafeAxios
      .post("file/groups", {
        name: groupName,
      })
      .then((res) => {
        if (bReadGroups) {
          getFileGroups();
        }
      })
      .catch((oError) => {
        console.error(
          `Error when adding group - ${groupName} - ::::::: ${oError}`
        );
      })
      .finally(() => {});
  };
  useEffect(() => {
    getFileGroups();
    // getUtilitySets();
  }, []);
  const formatFileSize = (number) => {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    }
  };
  const handleFileChange = async (file) => {
    try {
      if (!file || !validFileType(file)) {
        showToast("Selected file not supported!");
        return;
      }
      setIsFileSelected(true);
      setPiiState((prevState) => ({ ...prevState, START: true }));
      setUploadFile(file);
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array", sheetRows: 100 });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        onPiiIndentification(jsonData, file);
        if (Array.isArray(jsonData) && jsonData.length > 0) {
          const firstRow = jsonData[0];

          // Check if firstRow is an object (not an array)
          if (typeof firstRow === "object" && !Array.isArray(firstRow)) {
            const headers = Object.keys(firstRow);
            const tableData = headers.map((field, index) => ({
              fieldName: field,
              dataType: 1,
              anonMethod: 0,
              sensitivity: 0,
              sequenceNo: index + 1,
            }));
            setFileHeaders(tableData);
          } else {
            console.error(
              "The first row of jsonData is not an object:",
              firstRow
            );
          }
        } else {
          console.error("jsonData is not a valid array or is empty:", jsonData);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error occurred while handling file change:", error);
    }
  };

  const onPiiIndentification = (jsonData, file) => {
    setPiiState((prevState) => ({
      ...prevState,
      PII: true,
    }));
    const params = {
      file: jsonData,
      file_name: file.name,
    };
    serverAxios.defaultAxios
      .post(FULL_URL, params, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPiiState((prevState) => ({
          ...prevState,
          COLUMN_SELECT: true,
        }));

        const metaData = response.data.data.metaData;

        setFileMetadata(metaData);
        onExecuteClick(metaData, file);
      })
      .catch((error) => {
        showToast(error.message, "fail");
        console.error("Error:", error.message);
      })
      .finally(() => { });
  };
  const dataTypeMap = {
    Text: 1,
    Date: 2,
    Number: 3,
  };

  const utilityMap = {
    "No Change": "NO_CHANGE",
    Names: "NAME",
    Emails: "EMAIL",
    Phone: "PHONE",
    "Consistent ID": "CONSISTENT_ID",
    "Consistent Text": "CONSISTENT_ID",
    Random: "NO_CHANGE",
    "Date Same Year": "DATE",
    "Date Adult": "DATE",
    "Consistent Date": "NO_CHANGE",
    "Clear values": "CLEAR",
    "Differential Privacy": "DIFFERENTIAL_PRIVACY",
    Randomization: "RANDOMIZATION",
    "Consistent Number": "NO_CHANGE",
    Number: "NUMBER",
  };

  const privacyRelevanceMap = {
    "Not Relevant": 0,
    Confidential: 1,
    Personal: 2,
  };
  function formatLabel(value) {
    return value
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }
  const _getFormData = (metadata) => {
    let metadataList = metadata.map((header, index) => {
      return {
        name: header.fieldname,
        // dataType: (header.dataType).toUpperCase().split(' ').join('_'),
        // utilityParameter: header.utilityParameter,
        utilityParam: {
          label: header.utilityParameter,
          value: formatLabel(header.utilityParameter),
        },
        utilityParamConditions: [],
        privacyRelevance: header.privacyRelevance
          .toUpperCase()
          .split(" ")
          .join("_"),
        // sequenceNo: index + 1,
      };
    });
    const formData = {
      columnList: metadataList,
      seed: selectedGroup,
      jobName: "Job No - " + Math.floor(Math.random() * 1000),
    };
    return formData;
  };
  const onExecuteClick = async (metadata, file) => {
    setPiiState((prevState) => ({
      ...prevState,
      ANONYMIZE: true,
    }));
    let formData = new FormData();
    const dto = _getFormData(metadata);
    console.log("dto:: ", dto);
    formData.append("file", file);
    formData.append(
      "dto",
      new Blob([JSON.stringify(dto)], { type: "application/json" })
    );
    let uploaded = false;

    await serverAxios.fileSafeAxios
      .post("file/anonymize-engine", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.status !== 200) {
          console.error("There was an error!");
          showToast("here was an error!", "fail");
        } else {
          setPiiState((prevState) => ({
            ...prevState,
            DATA_SET_CREATE: true,
          }));
        toCheckDownloadStatus(res.data.data);
          showToast(
            "File has been uploaded to for processing. Please visit 'History' page for status."
          );
          uploaded = true;

          // setShow(true);
        }
      })
      .catch((oError) => {
        showToast(oError.message, "fail");
        console.error("There was an error!" + oError);
        // setError(oError.response.data);
      })
      .finally(() => {
        // if (uploaded) navigate("/history", { state: "fileSafe" });
      });
  };
  const toCheckDownloadStatus = async (id) => {
    setSeedToBeDownload(id);
    setPiiState((prevState) => ({
      ...prevState,
      FINISH: true,
    }));
  };
  const handleDownload = async (id) => {
    const oParameters = {
      responseType: "blob",
    };

    try {
      const res = await serverAxios.fileSafeAxios.get(
        `file/download/${id}`,
        oParameters
      );
      const fileData = res.data.data;

      const mimeType = fileData.type;
      let extension = "";

      if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        extension = "xlsx";
      } else if (
        mimeType === "text/csv" ||
        mimeType === "application/csv" ||
        mimeType === "text/comma-separated-values"
      ) {
        extension = "csv";
      }

      const pickerOptions = {
        suggestedName: `file.${extension}`,
        types: [
          {
            description: "Processed file",
            accept: {
              "text/csv": [".csv"],
              "application/csv": [".csv"],
              "text/comma-separated-values": [".csv"],
              "application/vnd.ms-excel": [".xlsx"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      };

      const fileHandle = await window.showSaveFilePicker(pickerOptions);
      const writable = await fileHandle.createWritable();

      await writable.write(fileData);
      await writable.close();
      showToast("File successfully downloaded", "success");
    } catch (error) {
      if (error.code === 20) {
        console.info("User canceled the download operation");
      }
      //  else if(error?.message) {
      //   console.log("Error when downloading the file:", error);
      //   showToast(error.message, "fail");
      // }
      else{
        showToast("File download failed!, Check history for status", "fail");
      }
      navigate('/history')
    } finally {
      setSeedToBeDownload(null)
    }
  };
  return (
    <>
      <Helmet>
        <title>File Anonymization - {REACT_APP_SITE_TITLE} </title>
      </Helmet>

      <div className="main-powered container">
        <div className="heading-container container">
          <div className="heading-top pt-2">
            <div className="pre">
              <span className="font-6">{t("premium")}</span>
              <p className="file  font-5">{t("Files")}</p>
            </div>
            <h1 className="font-6">AI-Powered Run</h1>
            <div className="discription font-4">{t("description")}</div>
          </div>
        </div>
        {isFileSelected ? (
          <div className="contain-steps container">
            <div className="hexagone-pattern ">
              <div className="col-one col">
                <div className="text-box-step text6">
                  <Steps
                    isDisabled={piiState.FINISH}
                    stepName="Finish"
                    discription="File ready for download"
                  />
                </div>
                <div className="text-box-step text5">
                  <Steps
                    isDisabled={piiState.DATA_SET_CREATE}
                    stepName="Create Data Set"
                    discription="Deleting original file and preparing anonymised one for download"
                  />
                </div>
                <div className="text-box-step text4">
                  <Steps
                    isDisabled={piiState.ANONYMIZE}
                    stepName="Anonymise"
                    discription="Apply utility parameters to selected fields"
                  />
                </div>
              </div>
              <div className=" col-two col">
                <img
                  className="maya-logo-loader"
                  src={mayaLogoLoader}
                  alt="img"
                />
              </div>
              <div className="col-three col">
                <div className="text-box-step text1">
                  <Steps
                    isDisabled={piiState.START}
                    stepName="Start"
                    discription="Uploading & Reading file"
                  />
                </div>
                <div className="text-box-step text3">
                  <Steps
                    isDisabled={piiState.PII}
                    stepName="PII Identification & Classification"
                    discription="Detect PII (Personally Identifiable Information), Quasi-PII, IDs, and Confidential Data"
                  />
                </div>
                <div className="text-box-step text2">
                  <Steps
                    isDisabled={piiState.COLUMN_SELECT}
                    stepName="Automatically Select & Configure"
                    discription="Classification Storage - automatically select the columns and appropriate algorithms for you. "
                  />
                </div>
              </div>
            </div>
            <div className="download-btn">
              <button
                className={`btn-purple ${piiState.DATA_SET_CREATE ? "" : "disabled"
                  }`}
                onClick={() =>
                  piiState.FINISH
                    ? handleDownload(seedToBeDownload)
                    : navigate("/history")
                }
              >
                {downloadLogo}{" "}
                {piiState.FINISH
                  ? "Download Anonymized Data Set"
                  : "Check Status"}{" "}
              </button>
            </div>
            {statusErrorMessage && (
              <>
                <div className="error mx-auto w-75 py-3 font-4 text-success border border-secondary">
                  <BiInfoCircle /> {t("description2")}
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="without-file container pt-2">
              <div
                className="drag-drop container"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleFileChange(e.dataTransfer.files[0])}
                onClick={() => inputRef.current.click()}
              >
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e.target.files[0])}
                  hidden
                  ref={inputRef}
                  accept=".csv,.xlsx"
                />
                <div className="drag-drop-content container cursor-pointer">
                  <div className="logo-upload container">{uploadLogo}</div>
                  <div className="upload-dis container">
                    <div className="selection-file">
                      <div
                        className="font-6"
                        style={{
                          color: "var(--primary-700, #6941C6)",
                          fontSize: "14px",
                        }}
                      >
                        {t("click")}{" "}
                      </div>{" "}
                      <div
                        className="type font-4"
                        style={{
                          color: "var(--gray-500, #667085)",
                          fontSize: "18px",
                        }}
                      >
                        {" "}
                        {t("drag")}
                      </div>
                    </div>
                    <div
                      className="file-type font-4"
                      style={{
                        color: " var(--gray-500, #667085)",
                        fontSize: "18px",
                      }}
                    >
                      {t("xls")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dis container font-4">
                <p
                  className="dis-data font-4 pt-1"
                  style={{ color: "color: var(--gray-500, #667085)" }}
                >
                  {t("desc")}{" "}
                  <a
                    className="font-4 cursor-pointer"
                    style={{
                      color: " var(--primary-700, #6941C6)",
                      fontSize: "18px",
                      textDecoration: "none",
                    }}
                    href={demoFileUrl}
                  >
                    {t("demo")}
                  </a>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default AiPowredRun;
