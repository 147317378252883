import React, { useState } from 'react'
import { InputSection, SelectSection } from '../../components/FieldSection.jsx/field'
import { availableFileSystemType } from './ConfigData'
import Sharepoint from './FTP/Sharepoint'

const FTP = ({ formDataForSource, handleChange, id, error }) => {
  const [sameConfig, setSameConfig] = useState(formDataForSource.sameConfig)

  const sharePointStructure = (subField) => {
    let fileType="";
    if (subField === "sourceConfig"){
      fileType = formDataForSource.sourceConfig.fileSystemType
    } else if (subField === 'targetConfig'){
      fileType = formDataForSource.targetConfig.fileSystemType
    }
    return (
      <div className=' d-flex  flex-column gap-3'>
        <SelectSection label='FileSystem Type' name="fileSystemType"  error={error}  onChange={(e) => handleChange(e, subField, 'fileSystemType', null)} value={formDataForSource?.fileSystemType} options={availableFileSystemType} uniqueId={id} required={true} />
        {fileType ==='SHAREPOINT'&&
          <Sharepoint handleChange={handleChange} formData={formDataForSource} error={error}  id={id} subField={subField}/>
        }
      </div>
    )
  }
  return (
    <div className='d-flex w-100 gap-4 '>
      <div className="w-50">
        <p className='text-level font-5'>Source</p>
        {sharePointStructure('sourceConfig')}
      </div>
      <div className="w-50">
        <div className="d-flex align-items-center gap-5 ">
          <p className='mr-auto text-level font-5 mb-0'>Target</p>
          <span className='font-4 d-flex gap-1'> <input
            type="checkbox"
            onClick={() => setSameConfig(!sameConfig)}
            checked={formDataForSource.sameConfig}
            onChange={async (e) => {
              await handleChange(e, null, 'sameConfig', null);
            }}
            className=' ml-4'
          />
            Target Same as Source</span></div>
        {formDataForSource.sameConfig === false &&<div className={`pt-3 `}>
          {sharePointStructure('targetConfig')}
        </div>}  </div>
    </div>
  )
}
export default FTP