import React from 'react';
import { RxCross2 } from 'react-icons/rx';

const ViewPanel = ({ data, isVisible }) => {
    if (!data || typeof data !== 'object') {
        return null; 
    }

    const renderDetails = (data, depth = 0) => {
        return Object.keys(data).map((key, index) => {
            let renderedValue = renderValue(data[key], depth + 1);
            let isSubtopic = typeof data[key] === 'object';
    
            if ((/(Id)$/g.test(key)) || key.toLowerCase() === "id" || (/(Password)$/g.test(key))) {
                return null;
            }
    
            if ((/(At)$/g.test(key)) || (/(Time)$/g.test(key))) {
                renderedValue = new Date(renderedValue).toLocaleString();
            }
    
            return (
                <div key={index} className={` float-left`}>
                <div className={``}>
                    <span className={`level-text  ${isSubtopic ?`my-3 font-${7-depth} ` : 'font-4 '}`}>
                        {key} :{"  "}
                    </span> 
                    {renderedValue===null?'No Data':renderedValue}
                </div>
            </div>
            );
        }).filter(Boolean);
    };
    

    const renderValue = (value, depth) => {
        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                return value.map((item, index) => (
                    <div className='font-4' key={index}>
                        {renderValue(item, depth)}
                    </div>
                ));
            } else {
                return renderDetails(value, depth);
            }
        }
        return value;
    };

    return (
        <div className="panel-utility">
            <div className="panel-screen">
                <div className="container">
                    <div className="header-panel row justify-content-between">
                        <div className="left col-auto sub-heading font-5">View Details</div>
                        <div className='col-auto'>
                            <RxCross2 size={25} className='cursor-pointer' onClick={() => isVisible(false)} />
                        </div>
                    </div>
                    <div className="field-group-section-panel pt-3">
                        {renderDetails(data)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewPanel;
