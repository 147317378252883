import "./History.scss";
import TablePro from "../../components/newTable/TablePro";
import { useEffect, useState } from "react";
import axios from "axios";
import { useUserContext } from "../../hooks/UserContext";
import { FiInfo } from "react-icons/fi";
import { FaRegCommentDots } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import Helmet from "react-helmet";
import { serverAxios } from "../../utils/AxiosUtils";
import ViewPanel from "../../components/View/ViewPanel";
import { useLocation } from "react-router-dom";
import useAppContext from "../../hooks/useAppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PiiHistory from "./piiHistory/PiiHistory";

const { REACT_APP_SITE_KEY, REACT_APP_SITE_TITLE } = process.env;

const History = () => {
  const { t } = useTranslation("history");

  const [userJobs, setUserJobs] = useState([]);
  const [userJobsForAppSafe, setUserJobsForAppSafe] = useState([]);
  const { user, setUser, setLoading } = useUserContext();
  const { popupModel } = useAppContext();
  const location = useLocation();
  const { state } = location;
  const [appSafeHistory, setAppSafeHistory] = useState("App Safe");
  const { showToast } = useUserContext();
  const [viewHistoryPanel, setViewHistoryPanel] = useState(false);
  const [commentMsg, setCommentMsg] = useState("");
  const [error, setError] = useState(null);
  const tabNames = ["App Safe", "File Safe", "AI Jobs"];
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const getUserJobs = () => {
    setLoading(true);
    serverAxios.fileSafeAxios
      .get("file/all-requests")
      .then((res) => {
        setUserJobs(res.data.data);
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError}`);
        showToast(oError.message, "fail");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const [errorMessage, setErrorMessage] = useState("No Run to Show");
  useEffect(() => {
    getUserJobsHistoryForAppSafe();
  }, []);

  const getUserJobsHistoryForAppSafe = () => {
    setLoading(true);
    serverAxios.appSafeAxios
      .get("jobs")
      .then((res) => {
        const sortedUserJobs = res.data.data.sort((a, b) => {
          return (
            new Date(a.startTime) - new Date(b.startTime) ||
            new Date(a.endTime) - new Date(b.endTime)
          );
        });
        setUserJobsForAppSafe(sortedUserJobs);
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError.message}`);
        showToast(oError.message, "fail");

        setErrorMessage(oError.message);
      })
      .finally(() => { 
        setLoading(false);
      });
  };
  const [userJobsForAppSafeByID, setUserJobsForAppSafeByID] = useState({});
  const getUserJobsHistoryForAppSafeByID = (id) => {
    setViewHistoryPanel(true);
    serverAxios.appSafeAxios
      .get(`jobs/${id}`)
      .then((res) => {
        setUserJobsForAppSafeByID(res.data.data);
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError}`);
        showToast(oError.message, "fail");
      })
      .finally(() => { });
  };

  useEffect(() => {
    if (state === "fileSafe") {
      setAppSafeHistory("File Safe");
    }
    getUserJobs();
    getUserJobsHistoryForAppSafe();
  }, []);

  useEffect(() => { }, [commentMsg]);

  const buttonDisableFormatter = (row) => {
    console.log(row.status);
    if (row.status === "FINISHED") return true;
    return false;
  };

  const formatBatchJob = (text) => {
    return text.length > 10 ? text.substring(0, 8) + "..." : text;
  };
  const handleDeleteRowOfAppSafe = (id) => {
    setLoading(true);

    serverAxios.appSafeAxios
      .post(`jobs/${id}/delete`)
      .then((res) => {
        showToast("Deleted", "success");
        getUserJobsHistoryForAppSafe();
      })
      .catch((oError) => {
        console.error(`Reading User Jobs ::::: ${oError}`);
        showToast(oError.message, "fail");
      })
      .finally(() => {
        //busy dialog of
        setLoading(false);
      });
  };
  const handleDelete = (transactionId) => {
    setLoading(true);
    axios
      .delete("/mdp/api/deletion/transaction", {
        data: {
          transactionId: transactionId,
        },
      })
      .then((res) => {
        let jobId = "";
        let updateList = userJobs.filter((job) => {
          jobId = job.transactionId === transactionId ? job.batchJobId : "";
          return job.transactionId != transactionId;
        });
        showToast(`Job ${jobId} has been Deleted!`);
        // setShowToast(true);
        getUserJobs();
      })
      .catch((oError) => {
        console.error(
          `Error when deleting User Job - ${transactionId} ::::: ${oError}`
        );
        showToast("Job delete failed", "fail");
        // setShowToast(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDownload = async (id) => {
    const oParameters = {
      responseType: "blob",
    };

    try {
      const res = await serverAxios.fileSafeAxios.get(
        `file/download/${id}`,
        oParameters
      );
      const fileData = res.data.data;

      const mimeType = fileData.type;
      let extension = "";

      if (
        mimeType ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        extension = "xlsx";
      } else if (
        mimeType === "text/csv" ||
        mimeType === "application/csv" ||
        mimeType === "text/comma-separated-values"
      ) {
        extension = "csv";
      }

      const pickerOptions = {
        suggestedName: `file.${extension}`,
        types: [
          {
            description: "Processed file",
            accept: {
              "text/csv": [".csv"],
              "application/csv": [".csv"],
              "text/comma-separated-values": [".csv"],
              "application/vnd.ms-excel": [".xlsx"],
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            },
          },
        ],
      };

      const fileHandle = await window.showSaveFilePicker(pickerOptions);
      const writable = await fileHandle.createWritable();

      await writable.write(fileData);
      await writable.close();

      showToast("File successfully downloaded", "success");
    } catch (error) {
      if (error.code === 20) {
        console.info("User canceled the download operation");
      } else {
        console.log("Error when downloading the file:", error);
        showToast("File download failed", "fail");
      }
    }
  };
  const aHeaders = [
    {
      name: t("jobname"),
      field: "name",
      type: "text",
      width: "5%",
    },
    {
      name: t("Comment"),
      field: "",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (item) => {
        const handleCommentUpdate = (e) => {
          axios
            .post("/mdp/api/job/comment", {
              jobId: item.batchJobId,
              comment: commentMsg,
            })
            .then((res) => {
              showToast(`Comment has been Added/Updated!`);
            })
            .catch((oError) => {
              console.error(
                `Comment has not been Added/Updated! ::::: ${oError}`
              );
            })
            .finally(() => {
              setLoading(false);
            });
        };

        const handleOnEntering = () => {
          const oParameters = {
            params: { jobId: item.batchJobId },
          };
          setLoading(true);

          setCommentMsg(" ");

          axios
            .get("/mdp/api/job/comment", oParameters)
            .then((res) => {
              if (res.data && res.data.comment) {
                setCommentMsg(res.data.comment.comment);
              }
            })
            .catch((oError) => {
              console.error(`Reading Job Comment ::::: ${oError}`);
            })
            .finally(() => {
              setLoading(false);
            });
        };

        const popover = (
          <Popover id="popover-basic" className="commentPopover">
            <Popover.Header as="h3">Comment</Popover.Header>
            <Popover.Body>
              <form>
                <textarea
                  id="commentTextArea"
                  name="commentTextArea"
                  rows="7"
                  value={commentMsg ? commentMsg : ""}
                  onChange={(e) => setCommentMsg(e.target.value)}
                ></textarea>
              </form>
              <button
                type="submit"
                className="update"
                onClick={handleCommentUpdate}
              >
                Update
              </button>
            </Popover.Body>
          </Popover>
        );
        return (
          <OverlayTrigger
            trigger="click"
            placement="right"
            onEntering={handleOnEntering}
            overlay={popover}
            rootClose={true}
          >
            <Button className="transparent">
              <FaRegCommentDots />
            </Button>
          </OverlayTrigger>
        );
      },
    },

    {
      name: t("Created Date"),
      field: "createdAt",
      type: "date",
      display: "date",
      width: "5%",
    },
    {
      name: t("Created Time"),
      field: "createdAt",
      type: "date",
      display: "time",
      width: "5%",
    },
    {
      name: t("Group"),
      field: "groupName",
      type: "text",
      width: "5%",
    },
    {
      name: t("Status"),
      field: "status",
      type: "status",
      width: "5%",
    },
    // {
    //     name: "Record Number",
    //     field: "numberOfRecord",
    //     type: "text",
    //     width: "5%",
    // },
    {
      name: t("Records Processed"),
      field: "recordProcessed",
      type: "text",
      width: "5%",
    },
    {
      name: t("Action"),
      field: "id",
      type: "button",
      buttonType: `btn download btn-success ${buttonDisableFormatter ? "btn-success" : "disabled"
        }`,
      text: "Download",
      onClick: handleDownload,
      width: "5%",
      enabled: buttonDisableFormatter,
      // enabledFn: buttonDisableFormatter,
    },
    // {
    //     name: "",
    //     field: "requestId",
    //     type: "button",
    //     buttonType: "delete",
    //     text: "Delete",
    //     onClick: handleDelete,
    //     width: "5%",
    //     // enabled: "deletionFlag",
    //     enabledFn: buttonDisableFormatter,
    // },
    // {
    //     name: "",
    //     field: "requestId",
    //     type: "button",
    //     buttonType: "btn-info download",
    //     text: "Share",
    //     onClick: "",
    //     width: "5%",
    //     enabled: false,
    //     enabledFn: "",
    // },
  ];

  const aHeadersForAppSafe = [
    {
      name: t("Run Name"),
      field: "name",
      type: "customControl",
      width: "4%",
      fnRenderCustomControl: (item) => {
        return (
          <div
            className="font-4 cursor-pointer"
            onClick={() => navigate(`/history/${item.id}`)}
          >
            {item.name}
          </div>
        );
      },
    },
    {
      name: t("ExecutionSet Name"),
      field: "executionSetName",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (item) => {
        return (
          <div
            className="font-4"
            onClick={() => navigate(`/history/${item.id}`)}
          >
            {item.executionSetName}
          </div>
        );
      },
    },

    {
      name: t("Created Date"),
      field: "createdAt",
      type: "date",
      display: "date",
      width: "5%",
    },

    {
      name: t("Schedule Time"),
      field: "scheduledTime",
      type: "customControl",
      width: "5%",
      fnRenderCustomControl: (item) => {
        const timeInMillis = item.scheduledTime;
        const date = new Date(timeInMillis);
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour12: true, // If locale is 'en-US', use 12-hour format, otherwise use 24-hour format
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        };
        return (
          <div className="font-4">{date.toLocaleString("en-US", options)}</div>
        );
      },
    },

    {
      name: t("Records Processed"),
      field: "recordProcessed",
      type: "text",
      width: "5%",
    },
    {
      name: t("Record Failed"),
      field: "recordFailed",
      type: "text",
      width: "5%",
    },

    {
      name: t("Start Time"),
      field: "startTime",
      type: "customControl",
      display: "time",
      width: "5%",
      fnRenderCustomControl: (item) => {
        return item.startTime
          ? new Date(item.startTime).toLocaleTimeString()
          : "...";
      },
    },
    {
      name: t("End Time"),
      field: "endTime",
      type: "customControl",
      display: "time",
      width: "5%",
      fnRenderCustomControl: (item) => {
        const startTime = new Date(item.startTime);
        const endTime = new Date(item.endTime);
        if (!item.startTime || !item.endTime || startTime > endTime) {
          return "...";
        }
        return item.status === "RUNNING" ? "..." : endTime.toLocaleTimeString();
      },
    },
    {
      name: t("Run Status"),
      field: "status",
      type: "status",
      width: "5%",
    },
    {
      name: t("Action"),
      field: "",
      type: "customControl",
      width: "10%",
      fnRenderCustomControl: (item) => {
        return (
          <div className="mid-table-col-5">
            <div className="action-btn-h">
              {/* <svg
                className="item-1 cursor-pointer"
                onClick={() => navigate(`/history/${item.id}`)}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M0.833252 9.9987C0.833252 9.9987 4.16658 3.33203 9.99992 3.33203C15.8333 3.33203 19.1666 9.9987 19.1666 9.9987C19.1666 9.9987 15.8333 16.6654 9.99992 16.6654C4.16658 16.6654 0.833252 9.9987 0.833252 9.9987Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.99992 12.4987C11.3806 12.4987 12.4999 11.3794 12.4999 9.9987C12.4999 8.61799 11.3806 7.4987 9.99992 7.4987C8.61921 7.4987 7.49992 8.61799 7.49992 9.9987C7.49992 11.3794 8.61921 12.4987 9.99992 12.4987Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}
              <svg
                className="item-1 cursor-pointer"
                onClick={async () =>
                  await popupModel(
                    "Removal Confirmation",
                    "Do you wish to proceed with deleting this row?",
                    "Delete",
                    () => handleDeleteRowOfAppSafe(item.id)
                  )
                }
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clipPath="url(#clip0_6470_50043)">
                  <path
                    d="M12.5001 7.5013L7.50008 12.5013M7.50008 7.5013L12.5001 12.5013M18.3334 10.0013C18.3334 14.6037 14.6025 18.3346 10.0001 18.3346C5.39771 18.3346 1.66675 14.6037 1.66675 10.0013C1.66675 5.39893 5.39771 1.66797 10.0001 1.66797C14.6025 1.66797 18.3334 5.39893 18.3334 10.0013Z"
                    stroke="#667085"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6470_50043">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        );
      },
    },
  ];
  const handleTabChange = (oEven) => {
    const tab = oEven.target.innerText;

    setAppSafeHistory(tab);
  };
  const activness = (tab) => {
    // const tab = oEven.target.innerText;
    return tab === appSafeHistory ? "activeTab" : "non-activeTab";
  };
  return (
    <>
      <Helmet>
        <title>History - {REACT_APP_SITE_TITLE} </title>
      </Helmet>
      {/* <Navbar /> */}
      <div className="historyContentWrapper">
        <h3 className="font-6">{t("Jobs")}</h3>
        <div className="settingsNavbar pt-4 ">
          <ul className="row font-4">
            {tabNames.map((tabName) => (
              <li
                key={tabName}
                onClick={handleTabChange}
                className={`font-6 col cursor-pointer  ${activness(tabName)}`}
              >
                {tabName}
              </li>
            ))}
          </ul>
        </div>
        <div className="main-content mt-4">
          <div className="table-content pt-4">
            {appSafeHistory === "App Safe" ? (
              <>
                {userJobsForAppSafe.length <= 0 ? (
                  <>
                    <div className="d-flex justify-content-center font-4">
                      {errorMessage}
                    </div>
                  </>
                ) : (
                  <>
                    
                    <div
                      className="responsive"
                      style={{ width: "99%", overflowX: "auto" }}
                    >
                      <TablePro
                        columns={aHeadersForAppSafe}
                        data={userJobsForAppSafe.sort(
                          (a, b) =>
                            new Date(b.createdAt) - new Date(a.createdAt)
                        )}
                        minRows={5}
                      refresh={true}
                      refreshHandler={getUserJobsHistoryForAppSafe}
                      showRecords={true}
                      // search={true}
                      />
                    </div>
                  </>
                )}
              </>
            ) : appSafeHistory === "File Safe" ? (
              <>
                <p>
                  <FiInfo color="#6941C6" className="historyInfoIcon" />{" "}
                  <span className="font-4">{t("Description")}</span>
                </p>
                <p>
                  <FiInfo color="#6941C6" className="historyInfoIcon" />{" "}
                  <span className="font-4">
                    {t("Description2")}{" "}
                    <a
                      className="font-6"
                      style={{ color: "var(--primary-700, #6941C6)" }}
                      href="mailto:info@mayadataprivacy.eu"
                    >
                      info@mayadataprivacy.eu
                    </a>{" "}
                    {t("text1")}
                  </span>
                </p>
                <div className="mt-4">
                  {userJobs.length <= 0 ? (
                    <>
                      <div className="d-flex justify-content-center font-4">
                        {t("text2")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="responsive"
                        style={{ width: "100%", overflowX: "auto" }}
                      >
                        <TablePro
                          columns={aHeaders}
                          data={userJobs.sort(
                            (a, b) =>
                              new Date(b.createdAt) - new Date(a.createdAt)
                          )}
                          minRows={5}
                        refresh={true}
                        refreshHandler={getUserJobs}
                        showRecords={true}
                        // search={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (<>
              {/* //AI History  */}
              <PiiHistory />
            </>)}
          </div>
        </div>
        {viewHistoryPanel && (
          <ViewPanel
            data={userJobsForAppSafeByID}
            isVisible={setViewHistoryPanel}
          />
        )}
      </div>
    </>
  );
};

export default History;
